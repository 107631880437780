import AssessmentIcon from '@mui/icons-material/Assessment';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendServerBaseURL } from '../../utils/backendServerBaseURL';
import useLocalStorage from '../../hooks/useLocalStorage';


const initialState = {
  guideline: null,
};

export const updateGuideline = createAsyncThunk(
  'guideline/updateGuideline',
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let body = {
      'text': payload.value,
    }

    let config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.put(`${backendServerBaseURL}/manager/guidelines/update/${thunkAPI.getState().guideline.guideline._id}`, body, config);

    console.log(response.data.payload)

    if (response.status === 200) {

    }
  }
);

export const loadGuideline = createAsyncThunk(
  'guideline/loadGuideline',
  async (_, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.post(`${backendServerBaseURL}/manager/guidelines/read`, {
      "terminal": thunkAPI.getState().menu.selectedTerminalId
    }, config);

    console.log(response.data.payload.guidelines[0])

    if (response.status === 200) {
      thunkAPI.dispatch(updateGuidelineValue(response.data.payload.guidelines[0]))
    }
  }
);

export const guidelineSlice = createSlice({
  name: 'guideline',
  initialState,
  reducers: {
    updateGuidelineValue: (state, action) => {
      state.guideline = action.payload;
    },
  }
})

export const {
  updateGuidelineValue,
} = guidelineSlice.actions;

export const selectGuideline = (state) => state.guideline.guideline;

export default guidelineSlice.reducer;
