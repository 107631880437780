import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Card,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadAllKiosks,
  selectAllKiosks,
  selectPage,
  selectPagesCount,
  updateOpenDeleteKioskDialog,
  updateSelectedKiosk,
} from "../../redux/slices/kioskSlice";
import { formatTime } from "../../utils/formatTime";
import Label from "../Label";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  selectSelectedTerminalId,
  selectMyTerminals,
} from "../../redux/slices/menuSlice";
import {
  updateOpenEditKioskDialog,
  deactivateKiosk,
} from "../../redux/slices/kioskSlice";

function KiosksTable() {
  const [open, setOpen] = React.useState(false);
  const allKiosks = useSelector(selectAllKiosks);
  const selectedTerminalId = useSelector(selectSelectedTerminalId);
  const myTerminals = useSelector(selectMyTerminals);
  const page = useSelector(selectPage);
  const pagesCount = useSelector(selectPagesCount);

  const dispatch = useDispatch();

  const theme = useTheme();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (selectedTerminalId !== 0) {
      dispatch(loadAllKiosks());
    }
  }, [selectedTerminalId]);

  return (
    <>
      <Box mb={2}>
        <Button variant="contained" onClick={handleClickOpen}>
          Create New Kiosk
        </Button>
      </Box>

      <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Latitude</TableCell>
                <TableCell>Longitude</TableCell>
                <TableCell>Status</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allKiosks.map(
                (row) =>
                  row.isActive === true && (
                    <TableRow key={row._id}>
                      <TableCell>{row._id}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row?.poi?.coordinates[0]}</TableCell>
                      <TableCell>{row?.poi?.coordinates[1]}</TableCell>
                      <TableCell>
                        <Label
                          variant="filled"
                          color={(row.status === false && "error") || "success"}
                        >
                          {row.status === true ? "Online" : "Offline"}
                        </Label>
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={1}>
                          <Button
                            variant="contained"
                            startIcon={<EditIcon />}
                            onClick={() => {
                              dispatch(updateSelectedKiosk(row));
                              dispatch(updateOpenEditKioskDialog(true));
                            }}
                          >
                            Edit
                          </Button>

                          <Button
                            variant="contained"
                            startIcon={<DeleteOutlineIcon />}
                            color="error"
                            onClick={() => {
                              dispatch(updateSelectedKiosk(row));
                              dispatch(updateOpenDeleteKioskDialog(true));
                            }}
                          >
                            Delete
                          </Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Divider />

        {pagesCount > 1 && (
          <Box p={2}>
            <Pagination count={pagesCount} color="primary" />
          </Box>
        )}
      </Card>

      {/* Terminal ID Dialog */}
      <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
        <DialogTitle>
          <Typography variant="h3" textAlign="center">
            Create New Kiosk
          </Typography>
          <Typography variant="body2" textAlign="center" color="text.secondary">
            Enter the following terminal ID in kiosk to add it to this terminal
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Typography variant="h2" textAlign="center" color="text.secondary">
            {myTerminals.map((terminal) => {
              if (terminal._id == selectedTerminalId) {
                return terminal.uniqueCode;
              }
            })}
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default KiosksTable;
