import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendServerBaseURL } from '../../utils/backendServerBaseURL';

const initialState = {
  callsData: null,

  // Pagination
  callsTablepage: 1,
  callsTablepagesCount: 1,
};

export const loadCallsData = createAsyncThunk(
  'calls/loadCallsData',
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("token", "")
    const pageSize = 10

    let config = {
      params: {
        filter: payload.filter,
        page: thunkAPI.getState().calls.callsTablepage,
        size: pageSize
      },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.get(`${backendServerBaseURL}/manager/airport/readcalls/${thunkAPI.getState().menu.selectedTerminalId}`, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(updateCallsData(response.data.payload))
      thunkAPI.dispatch(updateCallsTablepagesCount(Math.ceil((response.data.payload.stats.picked_calls + response.data.payload.stats.dropped_calls) / pageSize)))
    }
  }
);

export const callsSlice = createSlice({
  name: 'calls',
  initialState,
  reducers: {
    updateCallsData: (state, action) => {
      state.callsData = action.payload;
    },

    // Pagination
    updateCallsTablepage: (state, action) => {
      state.callsTablepage = action.payload;
    },
    updateCallsTablepagesCount: (state, action) => {
      state.callsTablepagesCount = action.payload;
    },

  }
})

export const {
  updateCallsData,

  // Pagination
  updateCallsTablepage,
  updateCallsTablepagesCount,
} = callsSlice.actions;


export const selectCallsData = (state) => state.calls.callsData;

export const selectCallsTablepage = (state) => state.calls.callsTablepage;
export const selectCallsTablepagesCount = (state) => state.calls.callsTablepagesCount;

export default callsSlice.reducer;
