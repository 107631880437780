import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import WeeklyInstalls from "../components/Dashboardpage/WeeklyInstalls";
import BarGraph from "../components/Dashboardpage/BarGraph";
import DevicesIcon from "@mui/icons-material/Devices";
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonIcon from '@mui/icons-material/Person';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { useDispatch, useSelector } from "react-redux";
import { loadDashboardStats, selectDashboardStats } from '../redux/slices/dashboardSlice'
import { selectSelectedTerminalId } from '../redux/slices/menuSlice'

export default function Dashboardpage() {
  const dispatch = useDispatch()
  const dashboardStats = useSelector(selectDashboardStats)
  const selectedTerminalId = useSelector(selectSelectedTerminalId)

  useEffect(() => {
    if (selectedTerminalId !== 0) {
      dispatch(loadDashboardStats({ filter: 'last 7 days' }))
    }
  }, [selectedTerminalId])

  return (
    <Grid
      container
      spacing={3}
      justifyContent={{ xs: "center", md: "flex-start" }}
    >
      <Grid item xs={12} md={4}>
        <WeeklyInstalls
          icon={
            <DevicesIcon height={24} width={24} />
          }
          value={dashboardStats?.total_feedbacks}
          label={'Total Feedback'}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <WeeklyInstalls
          icon={
            <PhoneAndroidIcon height={24} width={24} />
          }
          value={dashboardStats?.total_kiosks}
          label={'Total Kiosks'}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <WeeklyInstalls
          icon={
            <AdminPanelSettingsIcon height={24} width={24} />
          }
          value={dashboardStats?.total_terminals}
          label={'Total Terminal'}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <WeeklyInstalls
          icon={
            <PersonIcon height={24} width={24} />
          }
          value={dashboardStats?.total_agents}
          label={'Total Agents'}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <WeeklyInstalls
          icon={
            <LocalPhoneIcon height={24} width={24} />
          }
          value={dashboardStats?.total_calls}
          label={'Total Calls'}
        />
      </Grid>



      <Grid item xs={12} md={12}>
        <BarGraph dashboardStats={dashboardStats} />
      </Grid>
    </Grid>
  );
}
