import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Container } from '@mui/material';
import { AccordionActions } from '@mui/material';
import { Button, Box, Stack, Input } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import LinkIcon from '@mui/icons-material/Link';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import { loadChatbot } from '../redux/slices/chatbotSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedTerminalId } from '../redux/slices/menuSlice';
import { updateChatbot } from '../redux/slices/chatbotSlice';


export default function Chatbot() {
    const dispatch = useDispatch()

    const selectedTerminalId = useSelector(selectSelectedTerminalId)

    const [data, setData] = React.useState([
        // {
        //     id: uuidv4(),
        //     question: 'Find the laggage belt',
        //     type: 'link',
        //     options: [{
        //         id: uuidv4(),
        //         question: 'What is the airline name?',
        //         type: 'text',
        //         options: [
        //             {
        //                 id: uuidv4(),
        //                 question: 'Spice Jet',
        //                 type: 'link',
        //                 options: [{
        //                     id: uuidv4(),
        //                     answer: 'Belt 2',
        //                     type: 'answer'
        //                 }]
        //             },
        //             {
        //                 id: uuidv4(),
        //                 question: 'Air india',
        //                 type: 'link',
        //                 options: [{
        //                     id: uuidv4(),
        //                     answer: 'Belt 5',
        //                     type: 'answer'
        //                 }]
        //             }
        //         ]
        //     }]
        // },
        // {
        //     id: uuidv4(),
        //     question: 'Find the laggage belt',
        //     type: 'link',
        //     options: [{
        //         id: uuidv4(),
        //         question: 'What is the airline name?',
        //         type: 'text',
        //         options: [
        //             {
        //                 id: uuidv4(),
        //                 question: 'Spice Jet2',
        //                 type: 'link',
        //                 options: [{
        //                     id: uuidv4(),
        //                     answer: 'Belt 2',
        //                     type: 'answer'
        //                 }]
        //             },
        //             {
        //                 id: uuidv4(),
        //                 question: 'Air india2',
        //                 type: 'link',
        //                 options: [{
        //                     id: uuidv4(),
        //                     answer: 'Belt 5',
        //                     type: 'answer'
        //                 }]
        //             }
        //         ]
        //     }]
        // },
    ])

    const addAccordionOpenStatus = (data) => {
        data.map(d => {
            d.accordionOpenStatus = false

            if (Object.keys(d).includes('options')) {
                d.options = addAccordionOpenStatus(d.options)
            }
        })

        return data
    }

    React.useEffect(async () => {
        if (selectedTerminalId !== 0) {
            const chatbotValue = await dispatch(loadChatbot())
            let tempData = chatbotValue.payload
            tempData = addAccordionOpenStatus(tempData)
            console.log(tempData)
            setData(tempData)
            // await dispatch(updateChatbot({ chatbot: data }))
        }
    }, [selectedTerminalId])

    React.useEffect(async () => {
        if (data.length !== 0) {
            await dispatch(updateChatbot({ chatbot: data }))
        }
    }, [data])

    const updateQuestion = (id, arr, newQuestionValue) => {
        let tempArray = [...arr]
        tempArray.map(block => {
            if (block.id === id && block.type !== 'answer') {
                block.question = newQuestionValue
                console.log(block)
            }

            if (block.id === id && block.type === 'answer') {
                block.answer = newQuestionValue
                console.log(block)
            }

            if (block.type !== 'answer') {
                updateQuestion(id, block.options, newQuestionValue)
            }

        })

        return tempArray
    }

    const addLink = (id, arr) => {
        let tempArray = [...arr]
        tempArray.map(block => {
            if (block.id === id) {
                block.options.push({
                    id: uuidv4(),
                    question: 'Write link text here',
                    type: 'link',
                    accordionOpenStatus: true,
                    options: []
                })
            }

            if (block.type !== 'answer') {
                addLink(id, block.options)
            }
        })

        return tempArray
    }

    const addText = (id, arr) => {
        let tempArray = [...arr]
        tempArray.map(block => {
            if (block.id === id) {
                block.options.push({
                    id: uuidv4(),
                    question: 'Write Question here',
                    type: 'text',
                    accordionOpenStatus: true,
                    options: []
                })
            }

            if (block.type !== 'answer') {
                addText(id, block.options)
            }
        })

        return tempArray
    }

    const addAnswer = (id, arr) => {
        let tempArray = [...arr]
        tempArray.map(block => {
            if (block.id === id) {
                block.options.push({
                    id: uuidv4(),
                    answer: 'Write answer here',
                    type: 'answer'
                })
            }

            if (block.type !== 'answer') {
                addAnswer(id, block.options)
            }
        })

        return tempArray
    }

    const remove = (id, arr) => {
        let tempArray = [...arr]
        tempArray.map((block, index) => {
            if (block.id === id) {
                tempArray.splice(index, 1)
                return tempArray
            }

            if (block.type !== 'answer') {
                block.options = remove(id, block.options)
            }
        })

        return tempArray
    }

    const updateAccordionOpenStatus = (id, arr, value) => {
        let tempArray = [...arr]
        tempArray.map(block => {
            if (block.id === id) {
                block.accordionOpenStatus = value
            }

            if (block.type !== 'answer') {
                addLink(id, block.options)
            }
        })

        return tempArray
    }

    const block = (comp, level) => {
        if (comp.type === 'link') {
            return <Box sx={{ marginLeft: level * 4, }} mb={1} mr={1}>
                <Accordion expanded={comp.accordionOpenStatus} sx={{ backgroundColor: '#D6FCFF' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon onClick={() => { setData(updateAccordionOpenStatus(comp.id, data, !comp.accordionOpenStatus)) }} />}
                    >
                        <Box
                            onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}
                            sx={{ minWidth: '100%', display: 'flex', alignItems: 'center' }}
                        >
                            <Box sx={{ flexGrow: 1 }}>
                                <Input
                                    fullWidth
                                    value={comp.question}
                                    startAdornment={<LinkIcon sx={{ marginRight: 1, opacity: 0.7 }} />}
                                    onChange={(e) => {
                                        setData(data => {
                                            const updatedData = updateQuestion(comp.id, data, e.target.value)
                                            console.log(updatedData)
                                            return updatedData
                                        })
                                    }}
                                    disableUnderline
                                    placeholder="Type a question"
                                    sx={{ width: '100%' }}
                                />
                            </Box>

                            <Stack direction='row' spacing={3} mr={2} ml={2}>
                                <Stack direction='row' spacing={1}>
                                    <Button variant='outlined' size='small' onClick={async () => {
                                        await setData(data => {
                                            const updatedData = addLink(comp.id, data)
                                            return updatedData
                                        })
                                        await setData(updateAccordionOpenStatus(comp.id, data, true))
                                    }}>Add Link</Button>
                                    <Button variant='outlined' size='small' onClick={async () => {
                                        await setData(data => {
                                            const updatedData = addText(comp.id, data)
                                            return updatedData
                                        })
                                        await setData(updateAccordionOpenStatus(comp.id, data, true))
                                    }}>Add Text</Button>
                                    <Button variant='outlined' size='small' onClick={async () => {
                                        await setData(data => {
                                            const updatedData = addAnswer(comp.id, data)
                                            return updatedData
                                        })
                                        await setData(updateAccordionOpenStatus(comp.id, data, true))
                                    }}>Add Answer</Button>
                                    <Button variant='outlined' size='small' color='error' onClick={() => {
                                        setData(data => {
                                            const updatedData = remove(comp.id, data)
                                            return updatedData
                                        })
                                    }}>Remove</Button>
                                </Stack>
                            </Stack>
                        </Box>
                    </AccordionSummary>

                    {
                        comp.options.map(option => {
                            return block(option, level + 1)
                        })
                    }

                </Accordion>
            </Box>

        }


        if (comp.type === 'text') {
            return <Box sx={{ marginLeft: level * 4, }} mb={1} mr={1}>
                <Accordion sx={{ backgroundColor: '#FFFFD8' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon onClick={() => { setData(updateAccordionOpenStatus(comp.id, data, !comp.accordionOpenStatus)) }} />}
                    >
                        <Box
                            onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}
                            sx={{ minWidth: '100%', display: 'flex', alignItems: 'center' }}
                        >
                            <Box sx={{ flexGrow: 1 }}>
                                <Input
                                    fullWidth
                                    value={comp.question}
                                    onChange={(e) => {
                                        setData(data => {
                                            const updatedData = updateQuestion(comp.id, data, e.target.value)
                                            console.log(updatedData)
                                            return updatedData
                                        })
                                    }}
                                    startAdornment={<TextFormatIcon sx={{ marginRight: 1, opacity: 0.7 }} />}
                                    disableUnderline
                                    placeholder="Type a question"
                                    sx={{ width: '100%' }}
                                />
                            </Box>

                            <Stack direction='row' spacing={3} mr={2} ml={2}>
                                <Stack direction='row' spacing={1}>
                                    <Button variant='outlined' size='small' onClick={async () => {
                                        await setData(data => {
                                            const updatedData = addLink(comp.id, data)
                                            return updatedData
                                        })
                                        await setData(updateAccordionOpenStatus(comp.id, data, true))
                                    }}>Add Link</Button>
                                    <Button variant='outlined' size='small' onClick={async () => {
                                        await setData(data => {
                                            const updatedData = addText(comp.id, data)
                                            return updatedData
                                        })
                                        await setData(updateAccordionOpenStatus(comp.id, data, true))
                                    }}>Add Text</Button>
                                    <Button variant='outlined' size='small' onClick={async () => {
                                        await setData(data => {
                                            const updatedData = addAnswer(comp.id, data)
                                            return updatedData
                                        })
                                        await setData(updateAccordionOpenStatus(comp.id, data, true))
                                    }}>Add Answer</Button>
                                    <Button variant='outlined' size='small' color='error' onClick={() => {
                                        setData(data => {
                                            const updatedData = remove(comp.id, data)
                                            return updatedData
                                        })
                                    }}>Remove</Button>
                                </Stack>
                            </Stack>
                        </Box>
                    </AccordionSummary>

                    {
                        comp.options.map(option => {
                            return block(option, level + 1)
                        })
                    }
                </Accordion>
            </Box>

        }

        if (comp.type === 'answer') {
            return <Box sx={{ backgroundColor: '#D5FFE0', marginLeft: level * 3, borderRadius: 2 }} pl={3} pt={2} pb={2} mb={1} mr={1}>
                <Box
                    onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}
                    sx={{ minWidth: '100%', display: 'flex', alignItems: 'center' }}
                >
                    <Box sx={{ flexGrow: 1 }}>
                        <Input
                            fullWidth
                            value={comp.answer}
                            startAdornment={<QuestionAnswerOutlinedIcon sx={{ marginRight: 1, opacity: 0.7 }} />}
                            onChange={(e) => {
                                setData(data => {
                                    const updatedData = updateQuestion(comp.id, data, e.target.value)
                                    console.log(updatedData)
                                    return updatedData
                                })
                            }}
                            disableUnderline
                            placeholder="Type a answer"
                            sx={{ width: '100%' }}
                        />
                    </Box>


                    <Stack direction='row' spacing={3} mr={2} ml={2}>
                        <Stack direction='row' spacing={1}>
                            <Button variant='outlined' size='small' color='error' onClick={() => {
                                setData(data => {
                                    const updatedData = remove(comp.id, data)
                                    return updatedData
                                })
                            }}>Remove</Button>
                        </Stack>
                    </Stack>
                </Box>
            </Box>
        }
    }



    const addNewBlock = () => {
        setData(data => {
            let tempData = [...data]
            tempData.push(
                {
                    id: uuidv4(),
                    question: 'Write link text here',
                    accordionOpenStatus: true,
                    type: 'link',
                    options: []
                }
            )

            return tempData
        })
    }

    return (
        <Container>
            <Box mb={2}>
                <Button variant='contained' onClick={addNewBlock}>Add New Block</Button>
            </Box>

            {
                data.map((singleData) => {
                    return block(singleData, 0)
                })
            }
        </Container>
    )
}