import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
    Card, Divider,
    IconButton,
    Menu,
    MenuItem, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Typography
} from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    deleteFAQ,
    loadAllFAQs,
    selectFaqs,
    updateOpenEditFaqDialog,
    updateSelectedFAQ
} from "../../redux/slices/faqSlice";
import { selectSelectedTerminalId } from "../../redux/slices/menuSlice";
import { useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionActions } from "@mui/material";
import { Button, Box } from "@mui/material";

function MoreMenuButton({ faq }) {
    const dispatch = useDispatch()
    const menuRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <>
                <IconButton ref={menuRef} size="large" onClick={handleOpen}>
                    <MoreVertIcon width={20} height={20} />
                </IconButton>
            </>

            <Menu
                open={open}
                anchorEl={menuRef.current}
                onClose={handleClose}
                PaperProps={{
                    sx: { width: 200, maxWidth: "100%" },
                }}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <MenuItem >
                    <EditIcon width={20} height={20} />
                    <Typography variant="body2" sx={{ ml: 2 }}>
                        Edit
                    </Typography>
                </MenuItem>

                <Divider />
                <MenuItem sx={{ color: "error.main" }} >
                    <DeleteOutlineIcon width={20} height={20} />
                    <Typography variant="body2" sx={{ ml: 2 }}>
                        Delete
                    </Typography>
                </MenuItem>
            </Menu>
        </>
    );
}

function FAQsTable() {
    const feedbacks = useSelector(selectFaqs)
    const dispatch = useDispatch()
    const theme = useTheme();
    const selectedTerminalId = useSelector(selectSelectedTerminalId)

    useEffect(() => {
        if (selectedTerminalId !== 0) {
            dispatch(loadAllFAQs());
        }
    }, [selectedTerminalId])

    return (
        <>
            {feedbacks.map((row) => (
                <Card p={2} key={row._id} sx={{ marginBottom: 1 }}>
                    <Accordion >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{row.question}</Typography>
                            <Box sx={{ flexGrow: 1 }}>

                            </Box>
                            <Button
                                sx={{ marginRight: 1 }}
                                size="small"
                                startIcon={<EditIcon />}
                                onClick={(e) => { e.stopPropagation(); e.preventDefault(); dispatch(updateSelectedFAQ(row)); dispatch(updateOpenEditFaqDialog(true)) }}
                            >Edit</Button>

                            <Button
                                sx={{ marginRight: 2 }}
                                size="small"
                                startIcon={<DeleteOutlineIcon />}
                                color="error"
                                onClick={(e) => { e.stopPropagation(); e.preventDefault(); dispatch(updateSelectedFAQ(row)); dispatch(deleteFAQ()) }}
                            >Delete</Button>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Typography dangerouslySetInnerHTML={{ __html: row.answer }}>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Card>
            ))}
        </>
    );
}

export default FAQsTable;
