import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SignalWifi4BarIcon from '@mui/icons-material/SignalWifi4Bar';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import { Button, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import AgentsTable from "../components/Agents/AgentsTable";
import CreateNewAgentDialog from "../components/Agents/CreateNewAgentDialog";
import DeleteAgentDialog from "../components/Agents/DeleteAgentDialog";
import EditAgentDialog from "../components/Agents/EditAgentDialog";
import WeeklyInstalls from "../components/Dashboardpage/WeeklyInstalls";
import { loadAllAgents, updateOpenCreateNewAgentDialog } from "../redux/slices/agentSlice";
import { loadDashboardStats, selectDashboardStats } from '../redux/slices/dashboardSlice'
import { useSelector } from 'react-redux';
import { selectSelectedTerminalId } from '../redux/slices/menuSlice'
import { useState } from 'react';

export default function Agents({ socket }) {
  const dispatch = useDispatch()
  const dashboardStats = useSelector(selectDashboardStats)
  const selectedTerminalId = useSelector(selectSelectedTerminalId)
  const [onlineAgents, setonlineAgents] = useState(0)

  useEffect(() => {
    if (selectedTerminalId !== 0) {
      dispatch(loadAllAgents());
      dispatch(loadDashboardStats({ filter: 'last 7 days' }));
    }
  }, [selectedTerminalId])

  useEffect(() => {
    if (socket) {
      socket?.emit('getOnlineAgentsCount')

      socket?.on('getOnlineAgentsCount', (payload) => {
        setonlineAgents(payload.onlineAgentsCount)
      })
    }

    return () => {
      socket?.off('getOnlineAgentsCount')
    }
  }, [socket, selectedTerminalId])

  return (
    <>
      <Grid
        container
        spacing={3}
        justifyContent={{ xs: "center", md: "flex-start" }}
      >
        <Grid item xs={12} md={4}>
          <WeeklyInstalls
            icon={
              <PeopleAltIcon height={24} width={24} />
            }
            value={dashboardStats?.total_agents}
            label={'Total Agents'}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <WeeklyInstalls
            icon={
              <SignalWifi4BarIcon height={24} width={24} />
            }
            value={onlineAgents}
            label={'Online Agents'}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <WeeklyInstalls
            icon={
              <WifiOffIcon height={24} width={24} />
            }
            value={dashboardStats?.total_agents - onlineAgents}
            label={'Offline Agents'}
          />
        </Grid>


        <Grid item xs={12} md={12}>
          <Button variant="contained" onClick={() => {
            dispatch(updateOpenCreateNewAgentDialog(true));
          }}>Create New Agent</Button>
        </Grid>

        <Grid item xs={12} md={12}>
          <AgentsTable />
        </Grid>
      </Grid>

      <CreateNewAgentDialog />
      <DeleteAgentDialog />
      <EditAgentDialog />
    </>
  );
}
