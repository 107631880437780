import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Button, IconButton, Stack, TextField, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Form, FormikProvider, useFormik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import { selectOpenEditPOIDialog, selectSelectedPoi, updatePOI, updateOpenEditPOIDialog, deletePOI } from '../../redux/slices/mapsSlice';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import { useEffect } from 'react';
import { staticBaseURL } from '../../utils/backendServerBaseURL';
import { useRef } from 'react';


export default function EditPOIDialog() {
    const dispatch = useDispatch()
    const open = useSelector(selectOpenEditPOIDialog)
    const selectedPOI = useSelector(selectSelectedPoi)
    const [file, setFile] = React.useState(null)

    const RegisterSchema = Yup.object().shape({
        name: Yup.string().required('POI name is required'),
        description: Yup.string(),
        category: Yup.string(),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            category: '',
            description: ''
        },
        validationSchema: RegisterSchema,
        onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
            setSubmitting(true);
            console.log(values)
            await dispatch(updatePOI({
                'name': values.name,
                'category': values.category,
                'description': values.description,
                'imageFile': file,
                'resetForm': resetForm
            }))

            setSubmitting(false);
        }
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, handleChange, setFieldValue, values, submitForm } = formik;

    useEffect(() => {
        if (selectedPOI) {
            setFieldValue('name', selectedPOI.name)
            setFieldValue('description', selectedPOI.description)
            setFieldValue('category', selectedPOI.category)
        }
    }, [selectedPOI])

    const fileInputRef = useRef(null);

    const handleAttach = () => {
        fileInputRef.current.click();
    };

    return (
        <div>
            <Dialog
                open={open}
                fullWidth
                maxWidth='sm'
                onClose={() => { dispatch(updateOpenEditPOIDialog(false)) }}
            >
                <DialogContent>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ flexGrow: 1 }} variant='h6'>{"Edit POI details"}</Typography>

                        <Box>
                            <Button
                                color='error'
                                variant='outlined'
                                sx={{ marginRight: 2 }}
                                onClick={() => { dispatch(deletePOI({ poiId: selectedPOI._id })) }}
                            >Delete POI</Button>

                            <IconButton onClick={() => { dispatch(updateOpenEditPOIDialog(false)) }}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>


                    <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Stack spacing={2} mt={2}>
                                {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

                                <Box sx={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}>
                                    <img
                                        onClick={() => { handleAttach() }}
                                        src={file == null && selectedPOI?.imageURL != null ? staticBaseURL + "/" + selectedPOI?.imageURL : (file == null ? '' : URL.createObjectURL(file))}
                                        style={{ maxHeight: 100, maxWidth: 100, minHeight: 100, minWidth: 100, borderRadius: 8, border: 'solid', borderWidth: 1, borderColor: '#363636' }} />

                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        accept=".jpg, .jpeg, .png, .webp"
                                        onChange={() => {
                                            var files = fileInputRef.current.files;
                                            console.log(files)
                                            setFile(files[0])
                                        }} style={{ display: 'none' }}
                                    />
                                </Box>

                                <TextField
                                    fullWidth
                                    name="name"
                                    label="POI Name"
                                    color='primary'
                                    {...getFieldProps('name')}
                                    error={Boolean(touched.name && errors.name)}
                                    helperText={touched.name && errors.name}
                                />
                                {
                                    selectedPOI?.type === 'Dine' && <TextField
                                        fullWidth
                                        name="category"
                                        label="Category"
                                        color='primary'
                                        {...getFieldProps('category')}
                                        error={Boolean(touched.category && errors.category)}
                                        helperText={touched.category && errors.category}
                                    />
                                }


                                <TextField
                                    fullWidth
                                    name="description"
                                    multiline
                                    rows={4}
                                    label="POI Description"
                                    color='primary'
                                    {...getFieldProps('description')}
                                    error={Boolean(touched.description && errors.description)}
                                    helperText={touched.description && errors.description}
                                />

                                <LoadingButton type="submit" size='large' variant="contained" sx={{ marginRight: '0.5rem' }} loading={isSubmitting} onClick={submitForm}>
                                    Update
                                </LoadingButton>
                            </Stack>
                        </Form>
                    </FormikProvider>
                </DialogContent>
            </Dialog>
        </div>
    );
}