import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendServerBaseURL } from '../../utils/backendServerBaseURL';


const initialState = {
  faqs: [],
  selectedFAQ: null,

  // Dialogs
  openCreateNewFaqDialog: false,
  openEditFaqDialog: false,
};

export const CreateFAQ = createAsyncThunk(
  'faqSlice/CreateFAQ',
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let body = {
      "question": payload.question,
      "answer": payload.answer,
      "terminal": thunkAPI.getState().menu.selectedTerminalId,
      "category": thunkAPI.getState().faqCategory.selectedFAQCategory?._id
    }

    let config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.post(`${backendServerBaseURL}/manager/faq/create`, body, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(loadAllFAQs())
      thunkAPI.dispatch(updateOpenCreateNewFaqDialog(false))
      payload.resetForm()
    }
  }
);

export const updateFAQ = createAsyncThunk(
  'faqSlice/updateFAQ',
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let body = {
      "question": payload.question,
      "answer": payload.answer,
      "terminal": thunkAPI.getState().menu.selectedTerminalId
    }

    let config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.put(`${backendServerBaseURL}/manager/faq/update/${thunkAPI.getState().faq.selectedFAQ._id}`, body, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(loadAllFAQs())
      thunkAPI.dispatch(updateOpenEditFaqDialog(false))
      payload.resetForm()
    }
  }
);

export const deleteFAQ = createAsyncThunk(
  'faqSlice/deleteFAQ',
  async (_, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let config = {
      params: {},
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.delete(`${backendServerBaseURL}/manager/faq/delete/${thunkAPI.getState().faq.selectedFAQ._id}`, config);

    if (response.status === 200) {
      thunkAPI.dispatch(loadAllFAQs())
    }
  }
);

export const loadAllFAQs = createAsyncThunk(
  'faqSlice/loadAllFAQs',
  async (_, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let config = {
      params: {
        category: thunkAPI.getState().faqCategory.selectedFAQCategory._id
      },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.get(`${backendServerBaseURL}/manager/faq/read/${thunkAPI.getState().menu.selectedTerminalId}`, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(updateFaqs(response.data.payload))
    }
  }
);

export const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    updateFaqs: (state, action) => {
      state.faqs = action.payload;
    },
    updateOpenCreateNewFaqDialog: (state, action) => {
      state.openCreateNewFaqDialog = action.payload;
    },
    updateOpenEditFaqDialog: (state, action) => {
      state.openEditFaqDialog = action.payload;
    },
    updateSelectedFAQ: (state, action) => {
      state.selectedFAQ = action.payload;
    },
  }
})

export const {
  updateFaqs,
  updateOpenCreateNewFaqDialog,
  updateOpenEditFaqDialog,
  updateSelectedFAQ
} = faqSlice.actions;

export const selectFaqs = (state) => state.faq.faqs;
export const selectOpenCreateNewFaqDialog = (state) => state.faq.openCreateNewFaqDialog;
export const selectOpenEditFaqDialog = (state) => state.faq.openEditFaqDialog;
export const selectSelectedFAQ = (state) => state.faq.selectedFAQ;


export default faqSlice.reducer;
