import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendServerBaseURL } from '../../utils/backendServerBaseURL';


const initialState = {
  myTerminals: [],
  selectedTerminalId: 0
};


// Terminals
export const loadMyTerminals = createAsyncThunk(
  'menu/loadMyTerminals',
  async (_, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let config = {
      params: {},
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.get(`${backendServerBaseURL}/manager/terminal/read`, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(updateMyTerminals(response.data.payload.terminals))

      if (response.data.payload.terminals.length != 0) {
        thunkAPI.dispatch(updateSelectedTerminalId(response.data.payload.terminals[0]._id))
      }
    }
  }
);

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    updateMyTerminals: (state, action) => {
      state.myTerminals = action.payload;
    },
    updateSelectedTerminalId: (state, action) => {
      state.selectedTerminalId = action.payload;
    },
  }
})

export const {
  updateMyTerminals,
  updateSelectedTerminalId
} = menuSlice.actions;

export const selectMyTerminals = (state) => state.menu.myTerminals;
export const selectSelectedTerminalId = (state) => state.menu.selectedTerminalId;

export default menuSlice.reducer;
