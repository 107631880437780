import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Card, Divider,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadAllFeedbacks,
  selectFeedbacks,
  selectFeedbacksPage,
  selectFeedbacksPagesCount,
  updatefeedbacksPage
} from "../../redux/slices/feedbackSlice";
import { formatTime } from '../../utils/formatTime';
import Label from "../Label";



function FeedbacksTable() {
  const feedbacks = useSelector(selectFeedbacks)
  const page = useSelector(selectFeedbacksPage)
  const pagesCount = useSelector(selectFeedbacksPagesCount)

  const dispatch = useDispatch()

  const theme = useTheme();

  return (
    <>
      <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Airport</TableCell>
                <TableCell>Terminal</TableCell>
                <TableCell>Kiosk</TableCell>
                <TableCell>Question</TableCell>
                <TableCell>Answer</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {feedbacks.map((row) => (
                <TableRow key={row._id}>
                  <TableCell>{row._id}</TableCell>
                  <TableCell>{row.airport.name}</TableCell>
                  <TableCell>{row.terminal.name}</TableCell>
                  <TableCell>{row.kiosk.name}</TableCell>
                  <TableCell>{row.question}</TableCell>
                  <TableCell>{row.answer}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Divider />


        {
          pagesCount > 1 && <Box p={2}>
            <Pagination count={pagesCount} onChange={(e, value) => { dispatch(updatefeedbacksPage(value)); dispatch(loadAllFeedbacks({})) }} color="primary" />
          </Box>
        }
      </Card>
    </>
  );
}

export default FeedbacksTable;
