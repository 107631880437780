import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendServerBaseURL } from '../../utils/backendServerBaseURL';


const initialState = {
  logs: [],
  page: 1,
  pagesCount: 1
};

export const loadLogs = createAsyncThunk(
  'log/loadLogs',
  async (_, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let config = {
      params: {},
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.get(`${backendServerBaseURL}/manager/log/read/${thunkAPI.getState().menu.selectedTerminalId}`, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(updateLogs(response.data.payload.logs))

      // if (response.data.payload.terminals.length != 0) {
      //   thunkAPI.dispatch(updateSelectedTerminalId(response.data.payload.terminals[0]._id))
      // }
    }
  }
);

export const logSlice = createSlice({
  name: 'log',
  initialState,
  reducers: {
    updateLogs: (state, action) => {
      state.logs = action.payload;
    },
    updatePage: (state, action) => {
      state.page = action.payload;
    },
    updatePagesCount: (state, action) => {
      state.pagesCount = action.payload;
    }
  }
})

export const {
  updateLogs,
  updatePage,
  updatePagesCount
} = logSlice.actions;

export const selectLogs = (state) => state.log.logs;
export const selectPage = (state) => state.log.page;
export const selectPagesCount = (state) => state.log.pagesCount;

export default logSlice.reducer;
