import { Navigate, useRoutes } from "react-router-dom";
import Agents from "../pages/Agents";
import Chatbot from "../pages/Chatbot";
import Dashboard from "../pages/Dashboard";
import Dashboardpage from "../pages/Dashboardpage";
import FAQCategories from "../pages/FAQCategories";
import FAQs from "../pages/FAQs";
import Feedbacks from "../pages/Feedbacks";
import Guidelines from "../pages/Guidelines";
import Kiosks from "../pages/Kiosks";
import Landing from "../pages/Landing";
import Login from "../pages/Login";
import Logs from "../pages/Logs";
import Maps from "../pages/Maps";
import Page404 from "../pages/Page404";
import Calls from "../pages/Calls";
import { useLocation } from "react-router-dom";

export default function Router({ socket }) {
  function RequireAuth({ children }) {
    const accessToken = localStorage.getItem("token", null);
    const location = useLocation()

    if (accessToken == null || accessToken == undefined) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
  }

  return useRoutes([
    {
      path: "dashboard",
      element: <Dashboard />,
      children: [
        { path: "", element: <RequireAuth><Dashboardpage /></RequireAuth> },
        { path: "dashboardpage", element: <RequireAuth><Dashboardpage /></RequireAuth> },
        { path: "agents", element: <RequireAuth><Agents socket={socket} /></RequireAuth> },
        { path: "kiosks", element: <RequireAuth><Kiosks socket={socket} /> </RequireAuth> },
        { path: "feedbacks", element: <RequireAuth><Feedbacks /></RequireAuth> },
        { path: "faqs/categories", element: <RequireAuth><FAQCategories /></RequireAuth> },
        { path: "faqs/:category", element: <RequireAuth><FAQs /></RequireAuth> },
        { path: "guidelines", element: <RequireAuth><Guidelines /></RequireAuth> },
        { path: "logs", element: <RequireAuth><Logs /></RequireAuth> },
        { path: "chatbot", element: <RequireAuth><Chatbot /></RequireAuth> },
        { path: "maps", element: <RequireAuth><Maps /></RequireAuth> },
        { path: "calls", element: <RequireAuth><Calls /></RequireAuth> },
      ],
    },

    {
      path: "/",
      element: <Login />,
      children: [],
    },

    {
      path: "404",
      element: <Page404 />,
      children: [],
    },

    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },

    {
      path: "login",
      element: <Login />,
      children: [],
    },

  ]);
}
