import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { Stack, Alert, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/system';
import { useSelector, useDispatch } from "react-redux";
import { selectOpenDeleteAgentDialog, updateOpenDeleteAgentDialog, deactivateAgent } from '../../redux/slices/agentSlice';


export default function DeleteAgentDialog() {
    const dispatch = useDispatch()
    const open = useSelector(selectOpenDeleteAgentDialog)

    return (
        <div>
            <Dialog
                open={open}
                fullWidth
                maxWidth='sm'
                onClose={() => { dispatch(updateOpenDeleteAgentDialog(false)) }}
            >
                <DialogContent>
                    <Typography variant='h6' sx={{ marginBottom: 1 }}>{"Delete Agent"}</Typography>

                    <Typography variant='body2'>{"Are you sure you want to delete this agent"}</Typography>

                    <Box sx={{ display: 'flex' }} mt={3}>
                        <Button variant='contained' color='error' sx={{ marginRight: 1 }} onClick={() => { dispatch(deactivateAgent()) }}>Delete</Button>
                        <Button variant='contained' onClick={() => { dispatch(updateOpenDeleteAgentDialog(false)) }}>Cancel</Button>
                    </Box>

                </DialogContent>
            </Dialog>
        </div>
    );
}