import AssessmentIcon from '@mui/icons-material/Assessment';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendServerBaseURL } from '../../utils/backendServerBaseURL';
import useLocalStorage from '../../hooks/useLocalStorage';


const initialState = {
  allAgents: [],
  selectedAgent: null,

  // Dialogs
  openCreateNewAgentDialog: false,
  openEditAgentDialog: false,
  openDeleteAgentDialog: false,

  // Pagination
  page: 1,
  pagesCount: 1,
};

export const CreateAgent = createAsyncThunk(
  'agent/CreateAgent',
  async (payload, thunkAPI) => {
    const { setErrors } = payload
    const token = await localStorage.getItem("token", "")

    let body = {
      'name': payload.name,
      'email': payload.email,
      'password': payload.password,
      "terminal": thunkAPI.getState().menu.selectedTerminalId
    }

    let config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.post(`${backendServerBaseURL}/manager/agent/create`, body, config).catch((error) => {
      if (error.response.status === 400) {
        setErrors({ afterSubmit: error.response.data.message });
      }
    });;

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(updateOpenCreateNewAgentDialog(false))
      thunkAPI.dispatch(loadAllAgents())
      thunkAPI.dispatch(updateSelectedAgent(null))
      payload.resetForm()
    }
  }
);

export const UpdateAgent = createAsyncThunk(
  'agent/UpdateAgent',
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let body = {
      'name': payload.name,
      'email': payload.email,
      'password': payload.password,
      "terminal": thunkAPI.getState().menu.selectedTerminalId
    }

    let config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.put(`${backendServerBaseURL}/manager/agent/update/${thunkAPI.getState().agent.selectedAgent._id}`, body, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(updateOpenEditAgentDialog(false))
      thunkAPI.dispatch(loadAllAgents())
      thunkAPI.dispatch(updateSelectedAgent(null))
      payload.resetForm()
    }
  }
);

export const loadAllAgents = createAsyncThunk(
  'agent/loadAllAgents',
  async (_, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let config = {
      params: {},
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.get(`${backendServerBaseURL}/manager/agent/read?search=&filter=${thunkAPI.getState().menu.selectedTerminalId}`, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(updateAllAgents(response.data.payload.agents))
    }
  }
);

export const deactivateAgent = createAsyncThunk(
  'agent/deactivateAgent',
  async (_, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let config = {
      params: {},
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.delete(`${backendServerBaseURL}/manager/agent/deactivate/${thunkAPI.getState().agent.selectedAgent._id}`, config);

    if (response.status === 200) {
      thunkAPI.dispatch(updateOpenDeleteAgentDialog(false))
      thunkAPI.dispatch(loadAllAgents())
    }
  }
);

export const agentSlice = createSlice({
  name: 'agent',
  initialState,
  reducers: {
    updateAllAgents: (state, action) => {
      state.allAgents = action.payload;
    },
    updateSelectedAgent: (state, action) => {
      state.selectedAgent = action.payload;
    },

    // Dialogs
    updateOpenCreateNewAgentDialog: (state, action) => {
      state.openCreateNewAgentDialog = action.payload;
    },
    updateOpenEditAgentDialog: (state, action) => {
      state.openEditAgentDialog = action.payload;
    },
    updateOpenDeleteAgentDialog: (state, action) => {
      state.openDeleteAgentDialog = action.payload;
    }
  }
})

export const {
  updateAllAgents,
  updateSelectedAgent,

  // Dialog
  updateOpenCreateNewAgentDialog,
  updateOpenEditAgentDialog,
  updateOpenDeleteAgentDialog
} = agentSlice.actions;

export const selectAllAgents = (state) => state.agent.allAgents;

export const selectPage = (state) => state.agent.page;
export const selectPagesCount = (state) => state.agent.pagesCount;

export const selectSelectedAgent = (state) => state.agent.selectedAgent;

export const selectOpenCreateNewAgentDialog = (state) => state.agent.openCreateNewAgentDialog;
export const selectOpenEditAgentDialog = (state) => state.agent.openEditAgentDialog;
export const selectOpenDeleteAgentDialog = (state) => state.agent.openDeleteAgentDialog;

export default agentSlice.reducer;
