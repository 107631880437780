import AssessmentIcon from '@mui/icons-material/Assessment';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendServerBaseURL } from '../../utils/backendServerBaseURL';
import useLocalStorage from '../../hooks/useLocalStorage';


const initialState = {
  allKiosks: [],
  selectedKiosk: null,
  onlineKioskCount: 0,

  // Dialog
  openEditKioskDialog: false,
  openDeleteKioskDialog: false,

  // Pagination
  page: 1,
  pagesCount: 1,
};

export const loadAllKiosks = createAsyncThunk(
  'kiosk/loadAllKiosks',
  async (_, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let config = {
      params: {},
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.get(`${backendServerBaseURL}/manager/kiosk/read?filter=${thunkAPI.getState().menu.selectedTerminalId}&search=`, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(updateAllKiosks(response.data.payload.kiosks))
    }
  }
);

export const editKiosk = createAsyncThunk(
  'kiosk/editKiosk',
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    // ${thunkAPI.getState().menu.selectedTerminalId}
    let response = await axios.put(`${backendServerBaseURL}/manager/kiosk/update/${thunkAPI.getState().kiosk.selectedKiosk._id}`, {
      name: payload.name,
      location: payload.location ? payload.location : '',
      lat: payload.latitude ? payload.latitude : '',
      long: payload.longitude ? payload.longitude : '',
      terminal: thunkAPI.getState().menu.selectedTerminalId
    }, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(loadAllKiosks())
      thunkAPI.dispatch(updateOpenEditKioskDialog(false))
    }
  }
);

export const deactivateKiosk = createAsyncThunk(
  'kiosk/deactivateKiosk',
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    // ${thunkAPI.getState().menu.selectedTerminalId}
    let response = await axios.delete(`${backendServerBaseURL}/manager/kiosk/deactivate/${thunkAPI.getState().kiosk.selectedKiosk._id}`, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(updateOpenDeleteKioskDialog(false))
      thunkAPI.dispatch(loadAllKiosks())

      payload.socket.emit('getOnlineKioskCount')
    }
  }
);

export const kioskSlice = createSlice({
  name: 'kiosk',
  initialState,
  reducers: {
    updateAllKiosks: (state, action) => {
      state.allKiosks = action.payload;
    },
    updateSelectedKiosk: (state, action) => {
      state.selectedKiosk = action.payload;
    },
    updateOnlineKioskCount: (state, action) => {
      state.onlineKioskCount = action.payload;
    },

    // Dialog
    updateOpenEditKioskDialog: (state, action) => {
      state.openEditKioskDialog = action.payload;
    },
    updateOpenDeleteKioskDialog: (state, action) => {
      state.openDeleteKioskDialog = action.payload;
    },
  }
})

export const {
  updateAllKiosks,
  updateSelectedKiosk,
  updateOnlineKioskCount,
  updateOpenDeleteKioskDialog,

  // Dialog
  updateOpenEditKioskDialog
} = kioskSlice.actions;

export const selectAllKiosks = (state) => state.kiosk.allKiosks;
export const selectPage = (state) => state.kiosk.page;
export const selectPagesCount = (state) => state.kiosk.pagesCount;
export const selectOnlineKioskCount = (state) => state.kiosk.onlineKioskCount;
export const selectOpenEditKioskDialog = (state) => state.kiosk.openEditKioskDialog;
export const selectOpenDeleteKioskDialog = (state) => state.kiosk.openDeleteKioskDialog;

export const selectSelectedKiosk = (state) => state.kiosk.selectedKiosk;

export default kioskSlice.reducer;
