import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import LogsTable from '../components/Logs/LogsTable'
import { loadLogs } from '../redux/slices/logSlice'
import { selectSelectedTerminalId } from '../redux/slices/menuSlice'
import { useSelector } from 'react-redux'

export default function Logs() {
    const dispatch = useDispatch()
    const selectedTerminalId = useSelector(selectSelectedTerminalId)

    useEffect(() => {
        if (selectedTerminalId !== 0) {
            dispatch(loadLogs())
        }
    }, [selectedTerminalId])

    return (
        <>
            <LogsTable />
        </>
    )
}
