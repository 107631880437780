import React, { useEffect, useRef } from 'react'
import SunEditor from 'suneditor-react';
import { Box } from '@mui/system';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import { selectGuideline, loadGuideline, updateGuideline } from '../redux/slices/guidelineSlice';
import { selectSelectedTerminalId } from '../redux/slices/menuSlice';
import { useDispatch, useSelector } from 'react-redux';

export default function Guidelines() {
    const dispatch = useDispatch()
    const editorRef = useRef('')

    const guideline = useSelector(selectGuideline)
    const selectedTerminalId = useSelector(selectSelectedTerminalId)

    useEffect(() => {
        if (selectedTerminalId !== 0) {
            dispatch(loadGuideline())
        }
    }, [selectedTerminalId])

    return (
        <Box>
            {
                guideline && <SunEditor
                    height="70vh"
                    inputRef={editorRef}
                    setContents={guideline?.text}
                    setDefaultStyle="font-family: Public Sans, sans-serif; font-size: 16px;"
                    onChange={(value) => {
                        dispatch(updateGuideline({ value }));
                    }}
                    setOptions={{
                        imageWidth: '690px',
                        buttonList: [
                            ['undo', 'redo'],
                            ['fontSize', 'formatBlock'],
                            ['paragraphStyle', 'blockquote'],
                            ['bold', 'underline', 'italic'],
                            ['list'],
                            ['fontColor', 'hiliteColor'],
                            ['image']
                        ]
                    }}
                />
            }
        </Box>
    )
}
