import ThemeConfig from "./theme";
import Router from "./routes";
import socketHelper from './utils/socketHelper';
import { useEffect, useState } from "react";
import useLocalStorage from "./hooks/useLocalStorage";



function App() {
  const [socket, setSocket] = useState(null)


  useEffect(async () => {
    const interval = await setInterval(function () {
      let user = localStorage.getItem('user', null)

      if (user !== undefined && user !== null) {
        // Connecting to socket will inform backend that this manager is currently online
        // manager online/offline status is shown in superadmin panel
        const iosocket = new socketHelper().socket;

        iosocket.on("me", (id) => {
          console.log('My socket ID - ', id);
          localStorage.setItem('mySocketId', id)
        });

        clearInterval(interval);

        setSocket(iosocket)
      }
    }, 500);
  }, [])


  return (
    <>
      <ThemeConfig>
        <Router socket={socket} />
      </ThemeConfig>
    </>
  );
}

export default App;
