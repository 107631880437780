import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { Stack, Alert, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { MenuItem } from '@mui/material';
import { Box } from '@mui/system';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { selectOpenEditKioskDialog, updateOpenEditKioskDialog, editKiosk, selectSelectedKiosk } from '../../redux/slices/kioskSlice';
import { useSelector, useDispatch } from "react-redux";


export default function EditKioskDialog() {
    const dispatch = useDispatch()
    const open = useSelector(selectOpenEditKioskDialog)
    const selectedKiosk = useSelector(selectSelectedKiosk)

    const RegisterSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        location: Yup.string(),
        latitude: Yup.string(),
        longitude: Yup.string()
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            location: '',
            latitude: '',
            longitude: ''
        },
        validationSchema: RegisterSchema,
        onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
            setSubmitting(true);

            await dispatch(editKiosk({
                'name': values.name,
                'location': values.location,
                'latitude': values.latitude,
                'longitude': values.longitude,
                'resetForm': resetForm
            }))

            setSubmitting(false);
        }
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, handleChange, setFieldValue, values, submitForm } = formik;


    useEffect(() => {
        if (selectedKiosk) {
            setFieldValue('name', selectedKiosk.name)
            setFieldValue('location', selectedKiosk?.location)
            setFieldValue('latitude', selectedKiosk?.lat)
            setFieldValue('longitude', selectedKiosk?.long)
        }
    }, [selectedKiosk])


    return (
        <div>
            <Dialog
                open={open}
                fullWidth
                maxWidth='sm'
                onClose={() => { dispatch(updateOpenEditKioskDialog(false)) }}
            >
                <DialogContent>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ flexGrow: 1 }} variant='h6'>{"Edit Kiosk"}</Typography>

                        <Box >
                            <IconButton onClick={() => { dispatch(updateOpenEditKioskDialog(false)) }}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>

                    <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Stack spacing={2} mt={2}>
                                {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

                                <TextField
                                    fullWidth
                                    name="name"
                                    label="Name"
                                    color='primary'
                                    {...getFieldProps('name')}
                                    error={Boolean(touched.name && errors.name)}
                                    helperText={touched.name && errors.name}
                                />

                                {/* <TextField
                                    fullWidth
                                    name="location"
                                    label="Location (Optional)"
                                    color='primary'
                                    {...getFieldProps('location')}
                                    error={Boolean(touched.location && errors.location)}
                                    helperText={touched.location && errors.location}
                                /> */}

                                <TextField
                                    fullWidth
                                    name="latitude"
                                    label="Latitude (Optional)"
                                    color='primary'
                                    {...getFieldProps('latitude')}
                                    error={Boolean(touched.latitude && errors.latitude)}
                                    helperText={touched.latitude && errors.latitude}
                                />

                                <TextField
                                    fullWidth
                                    name="longitude"
                                    label="Longitude (Optional)"
                                    color='primary'
                                    {...getFieldProps('longitude')}
                                    error={Boolean(touched.longitude && errors.longitude)}
                                    helperText={touched.longitude && errors.longitude}
                                />

                                <LoadingButton type="submit" size='large' variant="contained" sx={{ marginRight: '0.5rem' }} loading={isSubmitting} onClick={submitForm}>
                                    Save
                                </LoadingButton>
                            </Stack>
                        </Form>
                    </FormikProvider>
                </DialogContent>
            </Dialog>
        </div>
    );
}