import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendServerBaseURL } from '../../utils/backendServerBaseURL';


const initialState = {
  allFeedbackQuestions: [],
  selectedFeedbackQuestion: null,
  feedbacks: [],
  stats: null,

  // Dialogs
  openCreateNewFeedbackQuestionDialog: false,
  openEditFeedbackQuestionDialog: false,
  openDeleteFeedbackQuestionDialog: false,

  // Pagination
  page: 1,
  pagesCount: 1,
  feedbacksPage: 1,
  feedbacksPagesCount: 1,
};

export const CreateFeedbackQuestion = createAsyncThunk(
  'feedback/CreateFeedbackQuestion',
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let body = {
      "question": payload.question,
      "options": payload.options,
      "terminal": thunkAPI.getState().menu.selectedTerminalId
    }

    let config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.post(`${backendServerBaseURL}/manager/feedback/question/create`, body, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(updateOpenCreateNewFeedbackQuestionDialog(false))
      thunkAPI.dispatch(loadAllFeedbackQuestions())
      thunkAPI.dispatch(updateSelectedFeedback(null))
      payload.resetForm()
    }
  }
);

export const UpdateFeedbackQuestion = createAsyncThunk(
  'feedback/UpdateFeedbackQuestion',
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let body = {
      "question": payload.question,
      "options": payload.options,
      "terminal": thunkAPI.getState().menu.selectedTerminalId
    }

    let config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.put(`${backendServerBaseURL}/manager/feedback/question/update/${thunkAPI.getState().feedback.selectedFeedbackQuestion._id}`, body, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(updateOpenEditFeedbackQuestionDialog(false))
      thunkAPI.dispatch(loadAllFeedbackQuestions())
      thunkAPI.dispatch(updateSelectedFeedback(null))
      payload.resetForm()
    }
  }
);

export const loadAllFeedbackQuestions = createAsyncThunk(
  'feedback/loadAllFeedbackQuestions',
  async (_, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let config = {
      params: {},
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.get(`${backendServerBaseURL}/manager/feedback/question/read?search=&filter=${thunkAPI.getState().menu.selectedTerminalId}`, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(updateAllFeedbackQuestions(response.data.payload.feedbackQuestions))
    }
  }
);

export const deactivateFeedbackQuestion = createAsyncThunk(
  'feedback/deactivateFeedbackQuestion',
  async (_, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let config = {
      params: {},
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.delete(`${backendServerBaseURL}/manager/feedback/question/delete/${thunkAPI.getState().feedback.selectedFeedbackQuestion._id}`, config);

    if (response.status === 200) {
      thunkAPI.dispatch(updateOpenDeleteFeedbackQuestionDialog(false))
      thunkAPI.dispatch(loadAllFeedbackQuestions())
    }
  }
);

export const loadAllFeedbacks = createAsyncThunk(
  'feedback/loadAllFeedbacks',
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let targetPage = thunkAPI.getState().feedback.feedbacksPage
    if (Object.keys(payload).includes('page')) {
      targetPage = payload.page
    }

    let config = {
      params: {
        page: targetPage,
        size: 10,
      },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.get(`${backendServerBaseURL}/manager/feedback/read/${thunkAPI.getState().menu.selectedTerminalId}`, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(updateFeedbacks(response.data.payload.feedbacks))
      thunkAPI.dispatch(updateStats(response.data.payload.stats))
      thunkAPI.dispatch(updatefeedbacksPage(response.data.pager.page))
      thunkAPI.dispatch(updatefeedbacksPagesCount(response.data.pager.totalPages))
    }
  }
);

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    updateAllFeedbackQuestions: (state, action) => {
      state.allFeedbackQuestions = action.payload;
    },
    updateFeedbacks: (state, action) => {
      state.feedbacks = action.payload;
    },
    updateSelectedFeedback: (state, action) => {
      state.selectedFeedbackQuestion = action.payload;
    },
    updateStats: (state, action) => {
      state.stats = action.payload;
    },

    updatefeedbacksPage: (state, action) => {
      state.feedbacksPage = action.payload;
    },
    updatefeedbacksPagesCount: (state, action) => {
      state.feedbacksPagesCount = action.payload;
    },

    // Dialogs
    updateOpenCreateNewFeedbackQuestionDialog: (state, action) => {
      state.openCreateNewFeedbackQuestionDialog = action.payload;
    },
    updateOpenEditFeedbackQuestionDialog: (state, action) => {
      state.openEditFeedbackQuestionDialog = action.payload;
    },
    updateOpenDeleteFeedbackQuestionDialog: (state, action) => {
      state.openDeleteFeedbackQuestionDialog = action.payload;
    }
  }
})

export const {
  updateAllFeedbackQuestions,
  updateFeedbacks,
  updateSelectedFeedback,
  updateStats,
  updatefeedbacksPage,
  updatefeedbacksPagesCount,
  // Dialog
  updateOpenCreateNewFeedbackQuestionDialog,
  updateOpenEditFeedbackQuestionDialog,
  updateOpenDeleteFeedbackQuestionDialog
} = feedbackSlice.actions;

export const selectAllFeedbacks = (state) => state.feedback.allFeedbackQuestions;
export const selectFeedbacks = (state) => state.feedback.feedbacks;
export const selectStats = (state) => state.feedback.stats;
export const selectPage = (state) => state.feedback.page;
export const selectFeedbacksPage = (state) => state.feedback.feedbacksPage;
export const selectPagesCount = (state) => state.feedback.pagesCount;
export const selectFeedbacksPagesCount = (state) => state.feedback.feedbacksPagesCount;

export const selectSelectedFeedback = (state) => state.feedback.selectedFeedbackQuestion;

export const selectOpenCreateNewFeedbackDialog = (state) => state.feedback.openCreateNewFeedbackQuestionDialog;
export const selectOpenEditFeedbackDialog = (state) => state.feedback.openEditFeedbackQuestionDialog;
export const selectOpenDeleteFeedbackDialog = (state) => state.feedback.openDeleteFeedbackQuestionDialog;

export default feedbackSlice.reducer;
