import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, IconButton, Stack, TextField, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Form, FormikProvider, useFormik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import { selectOpenEditFaqCategoryDialog, selectSelectedFAQCategory, updateFAQCategory, updateOpenEditFaqCategoryDialog } from '../../redux/slices/faqCategorySlice';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import { useEffect } from 'react';
import { staticBaseURL } from '../../utils/backendServerBaseURL';
import { useRef } from 'react';


export default function EditFaqCategoryDialog() {
    const dispatch = useDispatch()
    const open = useSelector(selectOpenEditFaqCategoryDialog)
    const selectedFAQCategory = useSelector(selectSelectedFAQCategory)
    const [file, setFile] = React.useState(null)

    const RegisterSchema = Yup.object().shape({
        name: Yup.string().required('Category name is required')
    });

    const formik = useFormik({
        initialValues: {
            name: ''
        },
        validationSchema: RegisterSchema,
        onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
            setSubmitting(true);
            console.log(values)
            await dispatch(updateFAQCategory({
                'name': values.name,
                'imageFile': file,
                'resetForm': resetForm
            }))

            setSubmitting(false);
        }
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, handleChange, setFieldValue, values, submitForm } = formik;

    useEffect(() => {
        if (selectedFAQCategory) {
            setFieldValue('name', selectedFAQCategory.name)
        }
    }, [selectedFAQCategory])

    const fileInputRef = useRef(null);

    const handleAttach = () => {
        fileInputRef.current.click();
    };

    return (
        <div>
            <Dialog
                open={open}
                fullWidth
                maxWidth='sm'
                onClose={() => { dispatch(updateOpenEditFaqCategoryDialog(false)) }}
            >
                <DialogContent>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ flexGrow: 1 }} variant='h6'>{"Edit FAQ"}</Typography>

                        <Box >
                            <IconButton onClick={() => { dispatch(updateOpenEditFaqCategoryDialog(false)) }}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>


                    <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Stack spacing={2} mt={2}>
                                {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

                                <Box sx={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}>
                                    <img
                                        onClick={() => { handleAttach() }}
                                        src={file == null && selectedFAQCategory?.imageURL != null ? staticBaseURL + "/" + selectedFAQCategory?.imageURL : (file == null ? '' : URL.createObjectURL(file))}
                                        style={{ maxHeight: 100, maxWidth: 100, minHeight: 100, minWidth: 100, borderRadius: 8, border: 'solid', borderWidth: 1, borderColor: '#363636' }} />

                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        accept=".jpg, .jpeg, .png"
                                        onChange={() => {
                                            var files = fileInputRef.current.files;
                                            console.log(files)
                                            setFile(files[0])
                                        }} style={{ display: 'none' }}
                                    />
                                </Box>

                                <TextField
                                    fullWidth
                                    name="name"
                                    label="Category name"
                                    color='primary'
                                    {...getFieldProps('name')}
                                    error={Boolean(touched.name && errors.name)}
                                    helperText={touched.name && errors.name}
                                />

                                <LoadingButton type="submit" size='large' variant="contained" sx={{ marginRight: '0.5rem' }} loading={isSubmitting} onClick={submitForm}>
                                    Create
                                </LoadingButton>
                            </Stack>
                        </Form>
                    </FormikProvider>
                </DialogContent>
            </Dialog>
        </div>
    );
}