import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendServerBaseURL } from '../../utils/backendServerBaseURL';


const initialState = {
  faqCategories: [],
  selectedFAQCategory: null,

  // Dialogs
  openCreateNewFaqCategoryDialog: false,
  openEditFaqCategoryDialog: false,
  openDeleteFaqCategoryDialog: false,
};

export const CreateFAQCategory = createAsyncThunk(
  'faqCategorySlice/CreateFAQCategory',
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    const formData = new FormData();
    formData.append(`name`, payload.name)
    formData.append(`file`, payload['imageFile'])
    formData.append(`terminal`, thunkAPI.getState().menu.selectedTerminalId)

    let config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'content-type': 'multipart/form-data'
      }
    }

    let response = await axios.post(`${backendServerBaseURL}/manager/faq/category/create`, formData, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(loadAllFAQsCategories())
      thunkAPI.dispatch(updateOpenCreateNewFaqCategoryDialog(false))
      payload.resetForm()
    }
  }
);

export const updateFAQCategory = createAsyncThunk(
  'faqCategorySlice/updateFAQCategory',
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    const formData = new FormData();
    formData.append(`name`, payload.name)
    formData.append(`file`, payload['imageFile'])
    formData.append(`terminal`, thunkAPI.getState().menu.selectedTerminalId)

    let config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'content-type': 'multipart/form-data'
      }
    }

    let response = await axios.put(`${backendServerBaseURL}/manager/faq/category/update/${thunkAPI.getState().faqCategory.selectedFAQCategory._id}`, formData, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(loadAllFAQsCategories())
      thunkAPI.dispatch(updateOpenEditFaqCategoryDialog(false))
      payload.resetForm()
    }
  }
);

export const deleteFAQCategory = createAsyncThunk(
  'faqCategorySlice/deleteFAQCategory',
  async (_, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let config = {
      params: {},
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.delete(`${backendServerBaseURL}/manager/faq/category/delete/${thunkAPI.getState().faqCategory.selectedFAQCategory._id}`, config);

    if (response.status === 200) {
      thunkAPI.dispatch(loadAllFAQsCategories())
      thunkAPI.dispatch(updateOpenDeleteFaqCategoryDialog(false))

    }
  }
);

export const loadAllFAQsCategories = createAsyncThunk(
  'faqCategorySlice/loadAllFAQsCategories',
  async (_, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let config = {
      params: {},
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.get(`${backendServerBaseURL}/manager/faq/category/readAll/${thunkAPI.getState().menu.selectedTerminalId}`, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(updateFaqs(response.data.payload))
    }
  }
);

export const faqCategorySlice = createSlice({
  name: 'faqCategory',
  initialState,
  reducers: {
    updateFaqs: (state, action) => {
      state.faqCategories = action.payload;
    },
    updateOpenCreateNewFaqCategoryDialog: (state, action) => {
      state.openCreateNewFaqCategoryDialog = action.payload;
    },
    updateOpenEditFaqCategoryDialog: (state, action) => {
      state.openEditFaqCategoryDialog = action.payload;
    },
    updateOpenDeleteFaqCategoryDialog: (state, action) => {
      state.openDeleteFaqCategoryDialog = action.payload;
    },
    updateSelectedFAQCategory: (state, action) => {
      state.selectedFAQCategory = action.payload;
    },
  }
})

export const {
  updateFaqs,
  updateOpenCreateNewFaqCategoryDialog,
  updateOpenEditFaqCategoryDialog,
  updateOpenDeleteFaqCategoryDialog,
  updateSelectedFAQCategory
} = faqCategorySlice.actions;

export const selectFaqCategories = (state) => state.faqCategory.faqCategories;
export const selectOpenCreateNewFaqCategoryDialog = (state) => state.faqCategory.openCreateNewFaqCategoryDialog;
export const selectOpenEditFaqCategoryDialog = (state) => state.faqCategory.openEditFaqCategoryDialog;
export const selectOpenDeleteFaqCategoryDialog = (state) => state.faqCategory.openDeleteFaqCategoryDialog;
export const selectSelectedFAQCategory = (state) => state.faqCategory.selectedFAQCategory;


export default faqCategorySlice.reducer;
