import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { Stack, Alert, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { MenuItem } from '@mui/material';
import { Box } from '@mui/system';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { selectOpenEditAgentDialog, updateOpenEditAgentDialog, UpdateAgent, selectSelectedAgent } from '../../redux/slices/agentSlice';
import { useSelector, useDispatch } from "react-redux";


export default function EditAgentDialog() {
    const dispatch = useDispatch()
    const open = useSelector(selectOpenEditAgentDialog)
    const selectedAgent = useSelector(selectSelectedAgent)


    const RegisterSchema = Yup.object().shape({
        fullName: Yup.string().required('Full name is required'),
        email: Yup.string().email("Email must be a valid email address").required('Email is required'),
        password: Yup.string(),
    });

    const formik = useFormik({
        initialValues: {
            fullName: '',
            email: '',
            password: ''
        },
        validationSchema: RegisterSchema,
        onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
            setSubmitting(true);

            await dispatch(UpdateAgent({
                'name': values.fullName,
                'email': values.email,
                'password': values.password,
                'resetForm': resetForm
            }))

            setSubmitting(false);
        }
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, handleChange, setFieldValue, values, submitForm } = formik;


    useEffect(() => {
        if (selectedAgent) {
            setFieldValue('fullName', selectedAgent.name)
            setFieldValue('email', selectedAgent.email)
            setFieldValue('password', '')
        }
    }, [selectedAgent])


    return (
        <div>
            <Dialog
                open={open}
                fullWidth
                maxWidth='sm'
                onClose={() => { dispatch(updateOpenEditAgentDialog(false)) }}
            >
                <DialogContent>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ flexGrow: 1 }} variant='h6'>{"Edit Agent"}</Typography>

                        <Box >
                            <IconButton onClick={() => { dispatch(updateOpenEditAgentDialog(false)) }}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>

                    <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Stack spacing={2} mt={2}>
                                {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

                                <TextField
                                    fullWidth
                                    name="fullName"
                                    label="Full Name"
                                    color='primary'
                                    {...getFieldProps('fullName')}
                                    error={Boolean(touched.fullName && errors.fullName)}
                                    helperText={touched.fullName && errors.fullName}
                                />

                                <TextField
                                    fullWidth
                                    name="email"
                                    label="Email"
                                    color='primary'
                                    {...getFieldProps('email')}
                                    error={Boolean(touched.email && errors.email)}
                                    helperText={touched.email && errors.email}
                                />

                                <TextField
                                    fullWidth
                                    name="password"
                                    label="Password (Optional)"
                                    color='primary'
                                    {...getFieldProps('password')}
                                    error={Boolean(touched.password && errors.password)}
                                    helperText={touched.password && errors.password}
                                />

                                <LoadingButton type="submit" size='large' variant="contained" sx={{ marginRight: '0.5rem' }} loading={isSubmitting} onClick={submitForm}>
                                    Save
                                </LoadingButton>
                            </Stack>
                        </Form>
                    </FormikProvider>
                </DialogContent>
            </Dialog>
        </div>
    );
}