import { configureStore } from '@reduxjs/toolkit';
import agentReducer from './slices/agentSlice';
import feedbackReducer from './slices/feedbackSlice';
import kioskReducer from './slices/kioskSlice';
import menuReducer from './slices/menuSlice';
import dashboardReducer from './slices/dashboardSlice';
import guidelineReducer from './slices/guidelineSlice';
import logReducer from './slices/logSlice';
import faqReducer from './slices/faqSlice';
import faqCategoryReducer from './slices/faqCategorySlice';
import chatbotReducer from './slices/chatbotSlice';
import mapsReducer from './slices/mapsSlice';
import callsReducer from './slices/callsSlice';


export const store = configureStore({
  reducer: {
    agent: agentReducer,
    feedback: feedbackReducer,
    kiosk: kioskReducer,
    menu: menuReducer,
    dashboard: dashboardReducer,
    guideline: guidelineReducer,
    log: logReducer,
    faq: faqReducer,
    faqCategory: faqCategoryReducer,
    chatbot: chatbotReducer,
    maps: mapsReducer,
    calls: callsReducer,
  },
});
