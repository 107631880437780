import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendServerBaseURL } from '../../utils/backendServerBaseURL';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  floors: [],
  selectedPoi: null,
  openEditPOIDialog: false,
  reRenderPoints: uuidv4()
};

export const CreatePOI = createAsyncThunk(
  'mapsSlice/CreatePOI',
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let body = {
      "name": payload.name,
      "type": payload.type,
      "coordinates": payload.coordinates,
      "terminalId": thunkAPI.getState().menu.selectedTerminalId,
      "floorId": payload.floorId,
    }

    let config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.post(`${backendServerBaseURL}/manager/poi/create`, body, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      await thunkAPI.dispatch(loadAllFloors())
      return response.data.payload

      // thunkAPI.dispatch(updateOpenCreateNewFaqDialog(false))
      // payload.resetForm()
    }
  }
);

export const updatePOI = createAsyncThunk(
  'mapsSlice/updatePOI',
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    const formData = new FormData();
    formData.append(`name`, payload.name)
    formData.append(`description`, payload.description)
    formData.append(`category`, payload.category)
    formData.append(`file`, payload['imageFile'])
    formData.append(`poiId`, thunkAPI.getState().maps.selectedPoi._id)

    let config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'content-type': 'multipart/form-data'
      }
    }

    let response = await axios.put(`${backendServerBaseURL}/manager/poi/update`, formData, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(loadAllFloors())
      payload.resetForm()
      thunkAPI.dispatch(updateOpenEditPOIDialog(false))
    }
  }
);

export const deletePOI = createAsyncThunk(
  'mapsSlice/deletePOI',
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.delete(`${backendServerBaseURL}/manager/poi/${payload.poiId}`, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      await thunkAPI.dispatch(loadAllFloors())
      await thunkAPI.dispatch(updateReRenderPoints(uuidv4()))
      await thunkAPI.dispatch(updateOpenEditPOIDialog(false))
    }
  }
);

export const loadAllFloors = createAsyncThunk(
  'mapsSlice/loadAllFloors',
  async (_, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let config = {
      params: {

      },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.get(`${backendServerBaseURL}/manager/floor/read/${thunkAPI.getState().menu.selectedTerminalId}`, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(updateFloors(response.data.payload.floors))
    }
  }
);

export const mapsSlice = createSlice({
  name: 'maps',
  initialState,
  reducers: {
    updateFloors: (state, action) => {
      state.floors = action.payload;
    },
    updateSelectedPoi: (state, action) => {
      state.selectedPoi = action.payload;
    },
    updateOpenEditPOIDialog: (state, action) => {
      state.openEditPOIDialog = action.payload;
    },
    updateReRenderPoints: (state, action) => {
      state.reRenderPoints = action.payload;
    },
  }
})

export const {
  updateFloors,
  updateSelectedPoi,
  updateOpenEditPOIDialog,
  updateReRenderPoints
} = mapsSlice.actions;

export const selectFloors = (state) => state.maps.floors;
export const selectSelectedPoi = (state) => state.maps.selectedPoi;
export const selectOpenEditPOIDialog = (state) => state.maps.openEditPOIDialog;
export const selectReRenderPoints = (state) => state.maps.reRenderPoints;

export default mapsSlice.reducer;
