import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateNewFaqDialog from "../components/FAQs/CreateNewFaqDialog";
import EditFaqDialog from "../components/FAQs/EditFaqDialog";
import FAQsTable from '../components/FAQs/FAQsTable';
import { updateOpenCreateNewFaqDialog } from "../redux/slices/faqSlice";
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import { Box, Card, Stack } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { loadAllFAQsCategories, selectFaqCategories, updateOpenCreateNewFaqCategoryDialog, updateOpenEditFaqCategoryDialog, updateSelectedFAQCategory, updateOpenDeleteFaqCategoryDialog } from "../redux/slices/faqCategorySlice";
import CreateNewFaqCategoryDialog from "../components/FAQCategories/CreateNewFaqCategoryDialog";
import EditFaqCategoryDialog from "../components/FAQCategories/EditFaqCategoryDialog";
import { selectSelectedTerminalId } from "../redux/slices/menuSlice";
import { staticBaseURL } from "../utils/backendServerBaseURL";
import DeleteFAQCategoryDialog from "../components/FAQCategories/DeleteFAQCategoryDialog";


export default function FAQCategories() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const FaqCategories = useSelector(selectFaqCategories)

    const selectedTerminalId = useSelector(selectSelectedTerminalId)

    useEffect(() => {
        if (selectedTerminalId !== 0) {
            dispatch(loadAllFAQsCategories())
        }
    }, [selectedTerminalId])

    return (
        <>
            <Grid
                container
                spacing={3}
                justifyContent={{ xs: "center", md: "flex-start" }}
            >
                <Grid item xs={12} md={12}>
                    <Button variant="contained" sx={{ marginBottom: 2 }} onClick={() => {
                        dispatch(updateOpenCreateNewFaqCategoryDialog(true));
                    }}>Create New FAQ Category</Button>
                </Grid>

                <Grid item xs={12} md={12}>
                    <Grid spacing={2} container>
                        {
                            FaqCategories.map(category => {
                                return <Grid item xs={3}>
                                    <Card
                                        onClick={() => {
                                            dispatch(updateSelectedFAQCategory(category));
                                            navigate(`/dashboard/faqs/${category._id}`)
                                        }}
                                        sx={{ cursor: 'pointer', padding: 3 }}>

                                        <Stack spacing={3} mb={3}>
                                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                <Box
                                                    component="img"
                                                    sx={{
                                                        height: 100,
                                                        width: 100
                                                    }}
                                                    src={staticBaseURL + "/" + category.imageURL}
                                                />
                                            </Box>


                                            <Typography textAlign='center'>{category.name}</Typography>
                                        </Stack>

                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Button
                                                sx={{ marginRight: 1 }}
                                                variant='contained'
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    dispatch(updateSelectedFAQCategory(category));
                                                    dispatch(updateOpenEditFaqCategoryDialog(true))
                                                }}>Edit</Button>

                                            <Button
                                                variant='contained'
                                                color='error'
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    dispatch(updateSelectedFAQCategory(category));
                                                    dispatch(updateOpenDeleteFaqCategoryDialog(true))
                                                }}>Delete</Button>
                                        </Box>
                                    </Card>
                                </Grid>
                            })
                        }
                    </Grid>
                </Grid>
            </Grid>

            <CreateNewFaqCategoryDialog />
            <EditFaqCategoryDialog />
            <DeleteFAQCategoryDialog />
        </>
    )
}
