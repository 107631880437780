import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Card,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Stack,
  Button
} from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadAllAgents, selectAllAgents, selectPage,
  selectPagesCount,
  updateOpenDeleteAgentDialog,
  updateOpenEditAgentDialog,
  updateSelectedAgent
} from "../../redux/slices/agentSlice";
import { formatTime } from '../../utils/formatTime';
import Label from "../Label";


function MoreMenuButton({ agent, dispatch }) {
  const menuRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <>
        <IconButton ref={menuRef} size="large" onClick={handleOpen}>
          <MoreVertIcon width={20} height={20} />
        </IconButton>
      </>

      <Menu
        open={open}
        anchorEl={menuRef.current}
        onClose={handleClose}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem onClick={() => { dispatch(updateSelectedAgent(agent)); dispatch(updateOpenEditAgentDialog(true))() }}>
          <EditIcon width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Edit
          </Typography>
        </MenuItem>

        <Divider />
        <MenuItem sx={{ color: "error.main" }} onClick={() => { dispatch(updateSelectedAgent(agent)); dispatch(updateOpenDeleteAgentDialog(true)) }}>
          <DeleteOutlineIcon width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Delete
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

function AgentsTable() {
  const allAgents = useSelector(selectAllAgents)
  const page = useSelector(selectPage)
  const pagesCount = useSelector(selectPagesCount)

  const dispatch = useDispatch()

  const theme = useTheme();

  return (
    <>
      <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Terminal</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allAgents.map((row) => (
                <TableRow key={row._id}>
                  <TableCell>{row._id}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.terminal.name}</TableCell>
                  <TableCell align="right">
                    <Stack direction='row' spacing={1}>
                      <Button variant='contained' startIcon={<EditIcon />} onClick={() => { dispatch(updateSelectedAgent(row)); dispatch(updateOpenEditAgentDialog(true)) }}>
                        Edit
                      </Button>

                      <Button variant='contained' startIcon={<DeleteOutlineIcon />} color='error' onClick={() => { dispatch(updateSelectedAgent(row)); dispatch(updateOpenDeleteAgentDialog(true)) }}>
                        Delete
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Divider />

        {
          pagesCount > 1 && <Box p={2}>
            <Pagination count={pagesCount} color="primary" />
          </Box>
        }
      </Card>
    </>
  );
}

export default AgentsTable;
