import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SignalWifi4BarIcon from '@mui/icons-material/SignalWifi4Bar';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import { Button, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import KiosksTable from "../components/Kiosks/KiosksTable";
import WeeklyInstalls from "../components/Dashboardpage/WeeklyInstalls";
import { loadAllKiosks } from "../redux/slices/kioskSlice";
import { selectSelectedTerminalId } from '../redux/slices/menuSlice'
import { useSelector } from 'react-redux';
import { selectAllKiosks, selectOnlineKioskCount, updateOnlineKioskCount } from '../redux/slices/kioskSlice';
import EditKioskDialog from '../components/Kiosks/EditKioskDialog';
import DeleteKioskDialog from '../components/Kiosks/DeleteKioskDialog';


export default function Kiosks({ socket }) {
  const dispatch = useDispatch()
  const selectedTerminalId = useSelector(selectSelectedTerminalId)
  const allKiosks = useSelector(selectAllKiosks)
  const onlineKioskCount = useSelector(selectOnlineKioskCount)

  useEffect(() => {
    if (selectedTerminalId !== 0) {
      dispatch(loadAllKiosks());
    }
  }, [selectedTerminalId])

  useEffect(() => {
    if (socket) {
      socket?.emit('getOnlineKioskCount')

      socket?.on("getOnlineKioskCount", (payload) => {
        dispatch(updateOnlineKioskCount(payload.onlineKioskCount))
      });
    }

    return () => {
      socket?.off('getOnlineKioskCount')
    }
  }, [socket, selectedTerminalId])

  return (
    <>
      <Grid
        container
        spacing={3}
        justifyContent={{ xs: "center", md: "flex-start" }}
      >
        <Grid item xs={12} md={4}>
          <WeeklyInstalls
            icon={
              <PeopleAltIcon height={24} width={24} />
            }
            value={allKiosks?.length}
            label={'Total Kiosks'}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <WeeklyInstalls
            icon={
              <SignalWifi4BarIcon height={24} width={24} />
            }
            value={onlineKioskCount}
            label={'Online Kiosks'}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <WeeklyInstalls
            icon={
              <WifiOffIcon height={24} width={24} />
            }
            value={allKiosks?.length - onlineKioskCount < 0 ? 0 : allKiosks?.length - onlineKioskCount}
            label={'Offline Kiosks'}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <KiosksTable />
        </Grid>
      </Grid>

      <EditKioskDialog />
      <DeleteKioskDialog socket={socket} />
    </>
  );
}
