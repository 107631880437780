import io from "socket.io-client";
import { socketURL } from './backendServerBaseURL'

export default class socketHelper {
  constructor() {
    this.socket = io(socketURL, {
      transports: ["websocket"],
      query: {
        type: "manager",
        dbID: JSON.parse(localStorage.getItem('user', ''))['_id']
      },
    });
  }
}
