import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Form, FormikProvider, useFormik, FieldArray, Field, ErrorMessage } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { Stack, Alert, TextField, Typography, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import { UpdateFeedbackQuestion } from '../../redux/slices/feedbackSlice';
import { useSelector, useDispatch } from "react-redux";
import {
    selectOpenEditFeedbackDialog,
    updateOpenEditFeedbackQuestionDialog,
    selectSelectedFeedback
} from '../../redux/slices/feedbackSlice';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';



export default function EditFeedbackDialog() {
    const dispatch = useDispatch()
    const open = useSelector(selectOpenEditFeedbackDialog)
    const selectedFeedbackQuestionData = useSelector(selectSelectedFeedback)

    const RegisterSchema = Yup.object().shape({
        question: Yup.string().required('question is required'),
        options: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required("Name required"),
            })
        )
    });

    const formik = useFormik({
        initialValues: {
            question: '',
            options: [''],
        },
        validationSchema: RegisterSchema,
        onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
            console.log(values)
            setSubmitting(true);

            let tempOptions = []
            values.options.map(op => {
                tempOptions.push(op.name)
            })

            await dispatch(UpdateFeedbackQuestion({
                'question': values.question,
                'options': tempOptions,
                'resetForm': resetForm
            }))

            setSubmitting(false);
        }
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, handleChange, setFieldValue, values, submitForm } = formik;

    useEffect(() => {
        if (selectedFeedbackQuestionData) {
            console.log('DSSSSSSSS')
            console.log(selectedFeedbackQuestionData.options.map(data => { return { 'name': data } }))

            setFieldValue('question', selectedFeedbackQuestionData.question)
            setFieldValue('options', selectedFeedbackQuestionData.options.map(data => { return { 'name': data } }))
        }
    }, [selectedFeedbackQuestionData])

    return (
        <div>
            <Dialog
                open={open}
                fullWidth
                maxWidth='sm'
                onClose={() => { dispatch(updateOpenEditFeedbackQuestionDialog(false)) }}
            >
                <DialogContent>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ flexGrow: 1 }} variant='h6'>{"Edit feedback question"}</Typography>

                        <Box >
                            <IconButton onClick={() => { dispatch(updateOpenEditFeedbackQuestionDialog(false)) }}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>


                    <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Stack spacing={2} mt={2}>
                                {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

                                <TextField
                                    fullWidth
                                    name="question"
                                    label="Question"
                                    color='primary'
                                    {...getFieldProps('question')}
                                    error={Boolean(touched.question && errors.question)}
                                    helperText={touched.question && errors.question}
                                />

                                <FieldArray
                                    name="options"
                                    {...getFieldProps('options')}
                                    render={arrayHelpers => (
                                        <div>
                                            {
                                                values.options.map((option, index) => (
                                                    <div key={index}>
                                                        <Box sx={{ display: 'flex' }} mb={2}>
                                                            <Field
                                                                placeholder={`Option ${index + 1}`}
                                                                name={`options.${index}.name`}
                                                                style={{
                                                                    flexGrow: 1,
                                                                    padding: 2,
                                                                    paddingLeft: 12,
                                                                    height: '3rem',
                                                                    borderRadius: 8,
                                                                    borderWidth: 0.5,
                                                                    borderColor: '#ECEFF1',
                                                                    marginRight: 8
                                                                }}
                                                            />
                                                            <IconButton
                                                                onClick={() => arrayHelpers.remove(index)}
                                                            >
                                                                <RemoveIcon />
                                                            </IconButton>
                                                        </Box>
                                                    </div>
                                                ))
                                            }

                                            <Button
                                                onClick={() => arrayHelpers.push('')}
                                            >
                                                Add extra option
                                            </Button>
                                        </div>
                                    )}
                                />

                                <LoadingButton
                                    type="submit"
                                    size='large'
                                    variant="contained"
                                    sx={{ marginRight: '0.5rem' }}
                                    loading={isSubmitting}
                                    onClick={submitForm}
                                >
                                    update
                                </LoadingButton>
                            </Stack>
                        </Form>
                    </FormikProvider>
                </DialogContent>
            </Dialog>
        </div>
    );
}