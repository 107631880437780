import React, { Fragment, PureComponent, useEffect, useRef, useState } from "react";
import floor1 from "../geojson/floor1.json";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { IconButton, Box, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Grid, Stack } from "@mui/material";
import AddLocationIcon from '@mui/icons-material/AddLocation';
import { useDispatch, useSelector } from 'react-redux';
import { selectFloors, loadAllFloors, CreatePOI, updateOpenEditPOIDialog, updateSelectedPoi, selectReRenderPoints, updateReRenderPoints } from "../redux/slices/mapsSlice";
import { selectSelectedTerminalId } from '../redux/slices/menuSlice';
import EditPOIDialog from "../components/Maps/EditPOIDialog";
import { getMarkerColor } from "../utils/makerColor";
import { v4 as uuidv4 } from 'uuid';

const turf = require("@turf/turf");
const maptalks = window.maptalks;
const THREE = window.THREE;

var material = new THREE.MeshPhysicalMaterial({
    color: "rgb(101,160,180)",
});
var material1 = new THREE.MeshPhysicalMaterial({
    color: "#5c57b8",
});

function colorCode() {
    var makingColorCode = "0123456789ABCDEF";
    var finalCode = "#";
    for (var counter = 0; counter < 6; counter++) {
        finalCode = finalCode + makingColorCode[Math.floor(Math.random() * 16)];
    }
    return finalCode;
}

export default function Maps() {
    const dispatch = useDispatch()
    const mapRef = useRef('')
    const navigate = useNavigate();
    const markerRef = useRef(null)
    const [currentFloorIndex, setCurrentFloorIndex] = useState(0)
    const [currentMarker, setCurrentMarker] = useState(null)
    let poiVecorLayer = useRef('')

    const reRenderPoints = useSelector(selectReRenderPoints)
    const floors = useSelector(selectFloors)
    const floorsRef = useRef([])

    const selectedTerminalId = useSelector(selectSelectedTerminalId)

    useEffect(() => {
        if (selectedTerminalId !== 0) {
            dispatch(loadAllFloors())
        }
    }, [selectedTerminalId])

    const [state, setState] = useState({
        mapDom: null,
        map: null,
        threeLayer: new maptalks.ThreeLayer("t", {
            forceRenderOnMoving: true,
            forceRenderOnRotating: true,
            identifyCountOnEvent: 1,
            animation: true,
        }),
        floors: [],

        bottomHeight: 200,

        centerPoint: [77.0938378572464, 28.502667456763728],
        start: [116.6071580350399, 40.07685027410991],
        end: [116.60583972930908, 40.07436890516115],
    })

    const init = async () => {
        if (mapRef.current !== null && floors.length !== 0 && state.mapDom === null) {
            await setCurrentFloor()
            await setMapDom();
        }
    }
    useEffect(() => {
        init()
    }, [mapRef, floors])

    const init2 = async () => {
        if (state?.mapDom !== null && !state?.mapDom?.firstChild && mapRef.current !== null && floors.length !== 0) {
            const map = await initMap();
            await setState(oldState => {
                let tempState = { ...oldState }
                tempState.map = map
                return tempState
            })
            setMapEvents(map)
        }
    }
    useEffect(() => {
        init2()
    }, [state.mapDom])

    useEffect(() => {
        if (state?.mapDom?.firstChild) {
            setState((currentState) => {
                let temp = { ...currentState }
                temp['floors'].pop()
                temp['floors'].push(floors[currentFloorIndex])

                renderPoints(temp.floors[currentFloorIndex].pois, temp.floors[currentFloorIndex].floorNumber, temp.map)

                return temp
            })
        }
    }, [reRenderPoints])

    const setMapEvents = (map) => {
        map.on('mouseup', function (e) {

            if (markerRef.current !== null) {
                console.log(e)

                console.log(state)

                setState((oldState) => {
                    let pois = [...oldState.floors[currentFloorIndex].pois]
                    const name = 'poi'
                    pois.push({
                        "coordinates": [
                            e.coordinate.x,
                            e.coordinate.y
                        ],
                        "name": name,
                        "type": markerRef.current,
                        'color': getMarkerColor(markerRef.current)
                    })

                    renderPoints(pois, oldState.floors[currentFloorIndex].floorNumber, map)

                    // Update state
                    let floorsCopy = [...oldState.floors]
                    let targetFloor = { ...floorsCopy[currentFloorIndex] }
                    targetFloor.pois = pois
                    floorsCopy[currentFloorIndex] = targetFloor
                    oldState.floors = floorsCopy

                    // Update in backend
                    const response = dispatch(CreatePOI({
                        "name": name,
                        "type": markerRef.current,
                        "coordinates": [
                            e.coordinate.x,
                            e.coordinate.y
                        ],
                        "floorId": oldState.floors[currentFloorIndex]._id,
                    }))

                    response.then(res => {
                        dispatch(updateReRenderPoints(uuidv4()))
                    })

                    return oldState
                })

                console.log(state)
            }
        })
    }

    const setCurrentFloor = async () => {
        await setState((currentState) => {

            let temp = { ...currentState }
            temp['floors'].pop()
            temp['floors'].push(floors[currentFloorIndex])

            return temp
        })
    };

    const setMapDom = async () => {
        const dom = document.getElementById("map");
        dom.style.height = '90vh';
        dom.style.minWidth = '100%';
        document.body.style.padding = "0px";
        document.body.style.margin = "0px";

        await setState((currentState) => {
            let tempState = { ...currentState }
            tempState.mapDom = dom
            return tempState
        })
    };

    const initMap = async () => {
        var map = await new maptalks.Map("map", {
            center: [116.60583972930908, 40.07436890516115],
            zoom: 16.378740584656995,
            pitch: 68.0000000000001,
            bearing: 175,

            // centerCross: true,
            doubleClickZoom: false,
            baseLayer: new maptalks.TileLayer("tile", {
                urlTemplate:
                    "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
                subdomains: ["a", "b", "c", "d"],
                attribution:
                    '&copy; <a href="http://osm.org">OpenStreetMap</a> contributors, &copy; <a href="https://carto.com/">CARTO</a>',
            }),
        });

        await setState((currentState) => {
            currentState.map = map
            return currentState
        });

        render3dLayer(map);

        return map;
    };

    const render3dLayer = (map) => {
        const { floors, threeLayer } = state;
        // render 3d layers
        floorsRef.current = floors


        threeLayer.prepareToDraw = function (gl, scene, camera) {
            var light = new THREE.DirectionalLight(0xffffff);
            light.position.set(0, -10, 10).normalize();
            scene.add(light);
            scene.add(new THREE.AmbientLight("#fff", 0.4));
            camera.add(new THREE.PointLight("#fff", 0.5));

            floorsRef.current.forEach((f, index) => {
                renderFloor(f.coordinates, f.floorNumb);
                renderObstalces(f.obstacles, f.floorNumb);
                renderPoints(f.pois, f.floorNumb, map);
            });
        };
        threeLayer.addTo(map);
    };

    const renderFloor = (features, floor) => {
        const { threeLayer } = state;
        const bottomHeight = state.bottomHeight * floor;
        const extrudePolygons = [];
        features.forEach((coordinates) => {
            let arr = []
            coordinates.forEach(coarr => {
                arr.push([coarr[0], coarr[1]])
            })

            let data = window.gcoord.transform(
                {
                    "type": "Feature",
                    "geometry": {
                        "type": "Polygon",
                        "coordinates": [[...arr]]


                    }
                },
                window.gcoord.AMap,
                window.gcoord.WGS84
            );
            data.properties = data.properties || {};
            data.properties.height = 1;
            data.properties.bottomHeight = bottomHeight;

            const extrudePolygon = threeLayer.toExtrudePolygon(
                data,
                Object.assign({}, data.properties, { topColor: "#fff" }),
                material
            );
            extrudePolygons.push(extrudePolygon);
        });
        threeLayer.addMesh(extrudePolygons);
    };

    const renderObstalces = (obstacles, floor) => {
        const { threeLayer } = state;
        const bottomHeight = state.bottomHeight * floor;
        const extrudePolygons = [];
        obstacles.forEach((singleObstacles, index) => {
            let arr = []
            singleObstacles.coordinates.forEach(coarr => {
                arr.push([coarr[0], coarr[1]])
            })

            let f = window.gcoord.transform(
                {
                    "type": "Feature",
                    "geometry": {
                        "type": "Polygon",
                        "coordinates": [[...arr]]
                    }
                },
                window.gcoord.AMap,
                window.gcoord.WGS84
            );

            var newmat = new THREE.MeshPhysicalMaterial({
                color: colorCode(),
                transparent: true,
            });

            f.properties = f.properties || {};
            // f.properties.height = 1;
            f.properties.bottomHeight = bottomHeight;
            f.properties.height = 1 + Math.floor(Math.random() * 7) + 1;

            const extrudePolygon = threeLayer.toExtrudePolygon(
                f,
                Object.assign({}, f.properties, { topColor: "#fff" }),
                newmat
            );
            extrudePolygons.push({ polygon: extrudePolygon, index });
        });
        threeLayer.addMesh(extrudePolygons.map(data => data.polygon));

        extrudePolygons.forEach((meshData) => {
            meshData.polygon.on('click', () => { console.log('Obstacle clicked'); console.log(meshData.index) })
        })
    };

    const renderPoints = (points, floor, map) => {
        const bottomHeight = state.bottomHeight * floor;

        const pts = [];

        points.forEach((p, i) => {
            const tempPoint = p
            const point = new maptalks.Marker(
                p.coordinates,
                {
                    'symbol': {
                        'markerType': 'pin',
                        'markerFill': p.color ? p.color : 'red',
                        'markerFillOpacity': 1,
                        'markerLineColor': 'white',
                        'markerLineWidth': 0.2,
                        'markerLineOpacity': 1,
                        'markerWidth': 40,
                        'markerHeight': 40,
                        'markerDx': 0,
                        'markerDy': 0,
                        'markerOpacity': 1
                    },
                    properties: {
                        altitude: bottomHeight,
                        type: p.type
                    },
                }
            );
            pts[i] = point;

            point.on('click', (e) => {
                console.log('poi clicked')
                dispatch(updateSelectedPoi(tempPoint))
                dispatch(updateOpenEditPOIDialog(true))
            })
        });

        if (poiVecorLayer.current !== null) {
            map.removeLayer(poiVecorLayer.current)
        }


        poiVecorLayer.current = new maptalks.VectorLayer(`vector_${floor}`, pts, {
            enableAltitude: true, // enable altitude
            altitudeProperty: "altitude", // altitude property in properties, default by 'altitude'
        })

        poiVecorLayer.current.addTo(map);
    };

    const findPath = (start, end, obstacles, map) => {
        var options = {
            obstacles,
        };
        // var path = turf.shortestPath(start, end, options);

        var path = turf.lineString([
            [116.60595774650574, 40.074393534577595],
            [116.60585045814514, 40.07512420321374],
            [116.6063493490219, 40.07541564750507],
            [116.60629034042358, 40.07584254956758],
            [116.60622596740721, 40.07622019146903],
            [116.60662293434142, 40.0764418498706],
            [116.60713791847229, 40.07684822173291],
        ]);

        const pathLine = new maptalks.LineString(path.geometry.coordinates, {
            symbol: {
                lineColor: colorCode(),
                lineWidth: 4,
                lineDasharray: [5, 5],
                markerFile: "plane.png",
                markerPlacement: "vertex", //vertex, point, vertex-first, vertex-last, center
                markerVerticalAlignment: "middle",
                markerWidth: 30,
                markerHeight: 30,
            },
        });

        new maptalks.VectorLayer("pathLine", [pathLine], {
            enableAltitude: true,
        }).addTo(map);

        return path;
    };

    return (
        <Fragment>
            <Box onMouseUp={() => { markerRef.current = null; setCurrentMarker(null) }}>

                <Box
                    style={{ zIndex: 2, maxWidth: 'calc(100% - 440px)', maxHeight: 'calc(100vh - 130px)' }}
                    ref={el => { console.log(el); mapRef.current = el; }}
                    id="map"
                ></Box>

                <Stack spacing={1} sx={{ position: 'fixed', top: '130px', right: '80px', zIndex: 99 }}>
                    <Button
                        variant={currentMarker === 'Dine' ? 'contained' : 'outlined'}
                        startIcon={<AddLocationIcon />}
                        onMouseDown={() => { markerRef.current = 'Dine'; setCurrentMarker('Dine') }} >
                        Dine
                    </Button>
                    <Button
                        variant={currentMarker === 'Gate' ? 'contained' : 'outlined'}
                        color='secondary'
                        startIcon={<AddLocationIcon />}
                        onMouseDown={() => { markerRef.current = 'Gate'; setCurrentMarker('Gate') }} >
                        Gate
                    </Button>
                </Stack>

            </Box>

            <EditPOIDialog />
        </Fragment>
    );
}
