import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateNewFaqDialog from "../components/FAQs/CreateNewFaqDialog";
import EditFaqDialog from "../components/FAQs/EditFaqDialog";
import FAQsTable from '../components/FAQs/FAQsTable';
import { updateOpenCreateNewFaqDialog } from "../redux/slices/faqSlice";
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import { Box, Card, Stack } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { selectSelectedFAQ } from "../redux/slices/faqSlice";
import { selectSelectedFAQCategory } from "../redux/slices/faqCategorySlice";

export default function FAQs() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const selectedCategory = useSelector(selectSelectedFAQCategory)

    const categoryID = params.categoy;

    return (
        <>
            <Grid
                container
                spacing={3}
                justifyContent={{ xs: "center", md: "flex-start" }}
            >
                <Grid item xs={12} md={12}>
                    <Typography variant='body2'>Category</Typography>
                    <Typography variant='h4'>{selectedCategory.name}</Typography>
                </Grid>


                <Grid item xs={12} md={12}>
                    <Button variant="contained" sx={{ marginBottom: 2 }} onClick={() => {
                        dispatch(updateOpenCreateNewFaqDialog(true));
                    }}>Create New FAQ</Button>

                    <FAQsTable />
                </Grid>
            </Grid>

            <CreateNewFaqDialog />
            <EditFaqDialog />
        </>
    )
}
