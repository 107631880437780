import {
    Box, Card, CardHeader, Divider, Pagination, Stack, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField
} from "@mui/material";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
    loadCallsData,
    selectCallsData,
    selectCallsTablepagesCount,
    updateCallsTablepage
} from "../redux/slices/callsSlice";
import { selectSelectedTerminalId } from "../redux/slices/menuSlice";
import { staticBaseURL } from '../utils/backendServerBaseURL';
import { formatTime } from "../utils/formatTime";
import { useEffect } from "react";

export default function Calls() {
    const dispatch = useDispatch()
    const callsData = useSelector(selectCallsData)
    const params = useParams()
    const [terminalId, setTerminalId] = useState('all')
    const [filter, setFilter] = useState('last 7 days')
    const pagesCount = useSelector(selectCallsTablepagesCount)



    const selectedTerminalId = useSelector(selectSelectedTerminalId)

    useEffect(() => {
        if (selectedTerminalId !== 0) {
            dispatch(loadCallsData({ filter: 'last 7 days' }))
        }
    }, [selectedTerminalId])


    const handleFilterChange = (event) => {
        setFilter(event.target.value)
        dispatch(loadCallsData({ filter: event.target.value }))
    };

    const handlePageChange = (event, value) => {
        dispatch(updateCallsTablepage(value))
        dispatch(loadCallsData({ filter: filter }))
    };

    const formatDuration = (duration) => {
        if ((duration / (1000 * 60)).toString() !== 'NaN') {
            if (duration / (1000 * 60) > 1) {
                return (Math.round((duration / (1000 * 60)) * 100) / 100) + ' Min'
            } else {
                return (Math.round((duration / (1000)) * 100) / 100) + ' Sec'
            }
        } else {
            return '0 sec'
        }
    }

    return (
        <Card>
            <CardHeader
                sx={{ marginBottom: 3 }}
                title="Call Records"
                subheader=""
                action={
                    <Stack direction='row' spacing={2}>
                        <TextField
                            select
                            fullWidth
                            SelectProps={{ native: true }}
                            onChange={handleFilterChange}
                            sx={{
                                width: 150,
                                "& fieldset": { border: "0 !important" },
                                "& select": {
                                    pl: 1,
                                    py: 0.5,
                                    pr: "24px !important",
                                    typography: "subtitle2",
                                },
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: 0.75,
                                    bgcolor: "background.neutral",
                                },
                                "& .MuiNativeSelect-icon": {
                                    top: 4,
                                    right: 0,
                                    width: 20,
                                    height: 20,
                                },
                            }}
                        >
                            <option value={'last 24 hours'}>
                                Last 24 hours
                            </option>
                            <option value={'last 7 days'} selected={true}>
                                Last 7 days
                            </option>
                            <option value={'last month'}>
                                Last Month
                            </option>
                            <option value={'this year'}>
                                This year
                            </option>
                        </TextField>
                    </Stack>

                }
            />

            <Box>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Time</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Duration</TableCell>
                                <TableCell>Download Recording</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {callsData?.calls.map((row) => (
                                <TableRow key={row._id}>
                                    <TableCell>{row._id}</TableCell>
                                    <TableCell sx={{ textTransform: 'capitalize' }}>{row.type}</TableCell>
                                    <TableCell>{formatTime(row.createdAt)}</TableCell>
                                    <TableCell sx={{ textTransform: 'capitalize' }}>{row.status}</TableCell>
                                    <TableCell sx={{ textTransform: 'capitalize' }}>{formatDuration(row.duration)}</TableCell>
                                    <TableCell sx={{ textTransform: 'capitalize' }}>
                                        {
                                            Object.keys(row).includes('recordingFileName') && <a href={staticBaseURL + '/' + row.recordingFileName} target='_blank'>Download</a>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Divider />

                {
                    pagesCount > 1 && <Box p={2}>
                        <Pagination count={pagesCount} color="primary" onChange={handlePageChange} />
                    </Box>
                }
            </Box>
        </Card>
    );
}
