export const getMarkerColor = (type) => {
    let color = 'red'
    switch (type) {
        case 'Dine':
            color = '#0d8efd'
            break;
        case 'Gate':
            color = '#850071'
            break;
        default:
        // code block
    }

    return color
}