import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendServerBaseURL } from '../../utils/backendServerBaseURL';


const initialState = {

};

export const updateChatbot = createAsyncThunk(
  'chatbotSlice/updateChatbot',
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let body = {
      "chatbot": payload.chatbot,
    }

    let config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.put(`${backendServerBaseURL}/manager/chatbot/${thunkAPI.getState().menu.selectedTerminalId}`, body, config);

    console.log(response.data.payload)

    if (response.status === 200) {

    }
  }
);

export const loadChatbot = createAsyncThunk(
  'chatbotSlice/loadChatbot',
  async (_, thunkAPI) => {
    const token = await localStorage.getItem("token", "")

    let config = {
      params: {

      },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.get(`${backendServerBaseURL}/manager/chatbot/read/${thunkAPI.getState().menu.selectedTerminalId}`, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      return response.data.payload
    }
  }
);

export const chatbotSlice = createSlice({
  name: 'chatbot',
  initialState,
  reducers: {

  }
})

export const { } = chatbotSlice.actions;

export default chatbotSlice.reducer;
