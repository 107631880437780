import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Card,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Stack,
  Button
} from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLogs,
  selectPage,
  selectPagesCount
} from "../../redux/slices/logSlice";
import { formatTime } from '../../utils/formatTime';
import Label from "../Label";

function LogsTable() {
  const logs = useSelector(selectLogs)
  const page = useSelector(selectPage)
  const pagesCount = useSelector(selectPagesCount)

  const dispatch = useDispatch()

  const theme = useTheme();

  return (
    <>
      <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Session Id</TableCell>
                <TableCell>Airport</TableCell>
                <TableCell>Terminal</TableCell>
                <TableCell>Kiosk</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((row) => (
                <TableRow key={row._id}>
                  <TableCell>{row._id}</TableCell>
                  <TableCell>{row.sessionId}</TableCell>
                  <TableCell>{row.airport.name}</TableCell>
                  <TableCell>{row.terminal.name}</TableCell>
                  <TableCell>{row.kiosk.name}</TableCell>
                  <TableCell>{row.actions.map(data => {
                    return <Typography>{data.action}</Typography>
                  })}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Divider />

        {
          pagesCount > 1 && <Box p={2}>
            <Pagination count={pagesCount} color="primary" />
          </Box>
        }
      </Card>
    </>
  );
}

export default LogsTable;
