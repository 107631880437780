import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SignalWifi4BarIcon from '@mui/icons-material/SignalWifi4Bar';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FeedbacksTable from "../components/Feedbacks/FeedbacksTable";
import FeedbackQuestionsTable from "../components/Feedbacks/FeedbackQuestionsTable";
import CreateNewFeedbackDialog from "../components/Feedbacks/CreateNewFeedbackDialog";
import DeleteFeedbackDialog from "../components/Feedbacks/DeleteFeedbackDialog";
import EditFeedbackDialog from "../components/Feedbacks/EditFeedbackDialog";
import WeeklyInstalls from "../components/Dashboardpage/WeeklyInstalls";
import { loadAllFeedbacks, selectStats, updateOpenCreateNewFeedbackQuestionDialog } from "../redux/slices/feedbackSlice";
import { selectSelectedTerminalId } from '../redux/slices/menuSlice'
import { useSelect } from '@mui/base';
import { loadAllFeedbackQuestions } from '../redux/slices/feedbackSlice';

export default function Feedbacks() {
  const dispatch = useDispatch()
  const selectedTerminalId = useSelector(selectSelectedTerminalId)
  const stats = useSelector(selectStats)

  useEffect(() => {
    if (selectedTerminalId !== 0) {
      dispatch(loadAllFeedbackQuestions());
      dispatch(loadAllFeedbacks({ page: 1 }));
    }
  }, [selectedTerminalId])


  return (
    <>
      <Grid
        container
        spacing={3}
        justifyContent={{ xs: "center", md: "flex-start" }}
      >
        <Grid item xs={12} md={12}>
          <WeeklyInstalls
            icon={
              <PeopleAltIcon height={24} width={24} />
            }
            value={stats?.total_feedbacks}
            label={'Total Feedbacks'}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <Button variant="contained" sx={{ marginBottom: 1 }} onClick={() => {
            dispatch(updateOpenCreateNewFeedbackQuestionDialog(true));
          }}>Create New Feedback Question</Button>

          <FeedbackQuestionsTable />
        </Grid>

        <Grid item xs={12} md={12}>
          <Typography variant='h6' sx={{ marginBottom: 1 }}>Feedbacks</Typography>
          <FeedbacksTable />
        </Grid>
      </Grid>

      <CreateNewFeedbackDialog />
      <DeleteFeedbackDialog />
      <EditFeedbackDialog />
    </>
  );
}
